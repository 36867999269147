import React, { useEffect, useRef , useState, useContext} from "react";
import createGlobe from "cobe";
import "./SupportTeam.css";
import { SpaceBar } from "@mui/icons-material";
import AnimationCards from "./Animationcards";
import { useNavigate } from 'react-router-dom';
import axios from "axios";
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { Button, Form, Modal } from 'react-bootstrap';
import { useForm, Controller } from 'react-hook-form';
import PhoneInput from 'react-phone-number-input';
import { API_ENDPOINT } from '../../Constant/Constant';
import { UserContext } from "../../context/FormDataContext";
import qs from 'qs';


function SupportTeam() {
    const canvasRef = useRef();
    const containerRef = useRef();
    const globeRef = useRef(null);
    useEffect(() => {
        let phi = -1;
        let lastRenderTime = 0;
    
        const adjustGlobeSize = () => {
            const containerWidth = containerRef.current.offsetWidth;
            const globeSize = containerWidth * 0.9;
    
            canvasRef.current.width = globeSize * 2;
            canvasRef.current.height = globeSize * 2;
    
            if (globeRef.current) {
                globeRef.current.destroy();
            }
    
            globeRef.current = createGlobe(canvasRef.current, {
                devicePixelRatio: 1.0,
                width: globeSize * 2,
                height: globeSize * 2,
               // phi: 2.28,
                //theta: 0.36,
                phi: -95.7129 * (Math.PI / 180), // Convert degrees to radians
                theta: 37.0902 * (Math.PI / 180),
                dark: 1,
                scale: 1,
                diffuse: 2.2,
                //mapSamples: 3000,
                mapSamples: 7500,
                mapBrightness: 3.1,
                baseColor: [0.0, 0.2, 0.3],
                markerColor: [0.2, 0.97, 0.75],
                 glowColor: [0.1, 0.15, 0.25],
                //glowColor: [0.243, 0.074, 0.918],
                opacity: .90,
                markers: [
                    { location: [39.5501, -105.7821], size: 0.03 },
                    { location: [44.5000, -100.0000], size: 0.03 },
                    { location: [32.7157, -117.1611], size: 0.03 },
                    { location: [33.7490, -84.3880], size: 0.03 },
                    { location: [29.9511, -90.0715], size: 0.03 },
                    { location: [30.3322, -81.6557], size: 0.03 },
                    { location: [34.0522, -118.2437], size: 0.03 },
                    { location: [25.7617, -80.1918], size: 0.03 },
                    { location: [21.3069, -157.8583], size: 0.03 },
					{ location: [32.7767, -96.797], size: 0.03 },
					{ location: [33.4484, -112.074], size: 0.03 },
					{ location: [47.6062, -122.3321], size: 0.03 },
					{ location: [41.8781, -87.6298], size: 0.03 },
					{ location: [38.9072, -77.0369], size: 0.03 },
					{ location: [34.0522, -118.2437], size: 0.03 }, // Los Angeles, CA
					{ location: [25.7617, -80.1918], size: 0.03 }, // Miami, FL
					{ location: [21.3069, -157.8583], size: 0.03 }, // Honolulu, HI
					{ location: [32.7767, -96.797], size: 0.03 }, // Dallas, TX
					{ location: [33.4484, -112.074], size: 0.03 }, // Phoenix, AZ
					{ location: [47.6062, -122.3321], size: 0.03 }, // Seattle, WA
					{ location: [41.8781, -87.6298], size: 0.03 }, // Chicago, IL
					{ location: [39.9526, -75.1652], size: 0.03 }, // Philadelphia, PA
					{ location: [38.9072, -77.0369], size: 0.03 }, // Washington, D.C.
					{ location: [37.7749, -122.4194], size: 0.03 }, // San Francisco, CA
					{ location: [36.1699, -115.1398], size: 0.03 }, // Las Vegas, NV
					{ location: [44.0682, -114.742], size: 0.03 }, // Idaho (State Center)
					{ location: [46.8797, -110.3626], size: 0.03 }, // Montana (State Center)
					{ location: [45.2538, -69.4455], size: 0.03 }, // Maine (State Center)
					{ location: [32.7157, -117.1611], size: 0.03 },
					{ location: [33.749, -84.388], size: 0.03 },
					{ location: [29.9511, -90.0715], size: 0.03 },
					{ location: [30.3322, -81.6557], size: 0.03 },
					{ location: [39.5501, -105.7821], size: 0.03 },
					{ location: [44.5, -100.0], size: 0.03 },
                ],
                // onRender: (state) => {
                //     const now = Date.now();
                //     if (now - lastRenderTime > 16) { // ~60 FPS
                //         state.phi = phi;
                //         phi += 0.0002;
                //         lastRenderTime = now;
                //     }
                // },

                onRender: (state) => {
                    const now = Date.now();
                    if (now - lastRenderTime > 8) { // ~60 FPS
                        state.phi = phi;
                        phi += 0.005; // Increased from 0.0002 to 0.001 for faster rotation
                        lastRenderTime = now;
                    }
                },
            });
        };
    
        adjustGlobeSize();
        window.addEventListener("resize", adjustGlobeSize);
    
        return () => {
            window.removeEventListener("resize", adjustGlobeSize);
            if (globeRef.current) globeRef.current.destroy();
        };
    }, []);


    const [show,setShow]=useState(false);
    const navigate = useNavigate();
    const { updateUserFormData } = useContext(UserContext);
    const [isExpanded, setIsExpanded] = useState(false);
    const text = `By submitting this form, you consent to Rentid contacting you via SMS, phone, and email for information about our services, promotional offers, and updates. You understand and agree that Rentid may use automated technology including autodialing, AI generated messaging, and AI generated voice to contact you, and that consent is not a condition of purchase. Message frequency may vary, and message/data rates may apply. You can opt out at any time by following the instructions in our communications or by contacting us at contact@rentid.io.`;

    const shortText = text.slice(0, 120) + '...'; // First 160 characters + ellipsis
    const readMoreText = isExpanded ? text : shortText;
    const [value, setValue] = useState();

const validationSchema = Yup.object().shape({
    firstName: Yup.string().required('First Name is required'),
    lastName: Yup.string().required('Last Name is required'),
    // companyName: Yup.string().required('Company Name is required'),
    phone: Yup.string()
    .required('Phone number is required'),
    // .min(10, 'Phone number must be at least 10 digits'),
    email: Yup.string().email('Email is invalid').required('Email is required'),
    state: Yup.string().required('State is required'),
    fleet: Yup.string().required('Fleet size is required')
});

    const { register, handleSubmit, formState: { errors }, reset, control } = useForm({
        resolver: yupResolver(validationSchema)
    });

    const onSubmit = async (data) => {        
        try {
            // Prepare the form data object
            const formData = {
                'waitlist[first_name]': data.firstName || 'First Name',
                'waitlist[last_name]': data.lastName || 'Last Name',
                'waitlist[company_name]': data.companyName || 'Company Name',
                'waitlist[phone_number]': data.phone || 'Phone Number',
                'waitlist[email_address]': data.email || 'Email Address',
                'waitlist[state]': data.state || 'State',
                'waitlist[fleet_size]': data.fleet || 'Fleet Size',
            };
    
            // Convert formData object to URL-encoded string
            const urlEncodedData = qs.stringify(formData);
    
            // Configure the request
            let config = {
                method: 'post',
                // url: 'https://api.getrentid.io/api/waitlist/submit-waitlist',
                url: `${API_ENDPOINT}/waitlist/submit-waitlist`,
                headers: { 
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
                data: urlEncodedData
            };
    
            // Make the request
            axios.request(config)
                .then((response) => {
                    console.log(JSON.stringify(response.data));
                    updateUserFormData(data.firstName, data.lastName,data.companyName, data.phone,data.email, data.state,data.fleet);
                    navigate("/beta-signup");
                    // window.location.href = '/thankyou';
                })
                .catch((error) => {
                    console.error('Error submitting form:', error);
                });
    
            // Reset the form
            handleClose();
            reset();
        } catch (error) {
            console.error('Error submitting form:', error);
        }
    };


    const handleModal=()=>{
        setShow(!show)
    }
    const handleClose=()=>{
        setShow(false)
    }
    

    return (
        <section id="office" className="">

<div ref={containerRef} className="col-xl-5 col-lg-5 col-md-4 d-flex justify-content-center globe-sec">
<canvas
                className="canvas_globe"
                ref={canvasRef}
                style={{ width: 1000, height: 600, maxWidth: "100%", aspectRatio: 1 }}
            />
                      </div>
            
            <div className="container">
                <div className="row align-items-center"> <div className="col-xl-7 col-lg-7 col-md-8 p-0 ps-0">
                        <div className="col-12 px-0">
                            <h6>YOUR DIGITAL SUPPORT TEAM</h6>
                        </div>
                        <h2 className="">
                            The world's first rental <span className="first_i">back-office infrastructure</span>
                        </h2>
                        <p>
                        We automated administrative, operational and logistical rental tasks so you can focus on customers. 
                        </p>
                      
                       {/* <ul className="inner-froup-list">
                        <li> <button class="custom-btn">Guest Violations</button></li>
                        <li><button class="custom-btn">Resolution Center</button></li>
                        <li><button class="custom-btn">Roadside Assistance</button></li>
                        <li><button class="custom-btn">Collections & Recovery</button></li>
                        
                       </ul> */}
                    </div>
                    
                    <div ref={containerRef} className="col-xl-5 col-lg-5 col-md-4 d-flex justify-content-center">
                      
                    </div>
                </div>
                <AnimationCards/>
               <div className="join-btn-box text-center">
                   <a onClick={handleModal} class="btn btn-primary">Join the waitlist</a>
                            
                <Modal id="waitlist" className='joinlist' show={show} onHide={handleClose} centered>
                    <Modal.Header closeButton>
                        <Modal.Title>Join The Waitlist</Modal.Title>
                    </Modal.Header>
                    <Form onSubmit={handleSubmit(onSubmit)}>
                        <Modal.Body>
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="row">
                                        <div className="col-md-6">
                                            <Form.Group className="mb-3">
                                                <Form.Label htmlFor="first_name">First Name<span class="required">*</span></Form.Label>
                                                <Form.Control type="text" id="first_name" placeholder='Enter first name' name="waitlist[first_name]" {...register('firstName')}
                                                     />
                                                <Form.Control.Feedback type="invalid">
                                                    {errors.firstName?.message}
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                        </div>
                                        <div className="col-md-6">
                                            <Form.Group className="mb-3">
                                                <Form.Label htmlFor="last_name">Last Name<span class="required">*</span></Form.Label>
                                                <Form.Control type="text" id="last_name" placeholder='Enter last name' name="lastName" {...register('lastName')}
                                                    />
                                                <Form.Control.Feedback type="invalid">
                                                    {errors.lastName?.message}
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                        </div>
                                        <div className="col-md-12">
                                            <Form.Group className="mb-3">
                                                <Form.Label htmlFor="company_name">Company name (Optional)</Form.Label>
                                                <Form.Control type="text" id="company_name" placeholder='Enter company name' name="companyName" {...register('companyName')}
                                                  />
                                                    isInvalid={!!errors.companyName} 
                                                <Form.Control.Feedback type="invalid">
                                                    {errors.companyName?.message}
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                        </div>
                                        <div className="col-md-6">
                                       <Form.Group className="mb-3">
                                    <Form.Label htmlFor="phone_number">
                                        Phone number<span className="required">*</span>
                                    </Form.Label>
                                    <Controller
                                        name="phone"
                                        control={control}
                                        rules={{
                                            required: "Phone number is required",
                                            validate: (value) => value && value.length >= 10 || "Phone number must be valid",
                                        }}
                                        render={({ field }) => (
                                            <PhoneInput
                                                {...field}
                                                id="phone_number"
                                                limitMaxLength="10"
                                                defaultCountry="US"
                                                placeholder="(000) 000-000"
                                                onChange={(value) => field.onChange(value)} // Ensure React Hook Form value updates
                                                value={field.value || ''}
                                            />
                                        )}
                                    />
                                    {errors.phone && (
                                        <Form.Control.Feedback type="invalid" className="d-block">
                                            {errors.phone.message}
                                        </Form.Control.Feedback>
                                    )}
                                </Form.Group>

                                        </div>
                                        <div className="col-md-6">
                                            <Form.Group className="mb-3">
                                                <Form.Label htmlFor="email_address">Email address<span class="required">*</span></Form.Label>
                                                <Form.Control type="email" id="email_address" placeholder='Enter your email' name="email" {...register('email')}
                                                    isInvalid={!!errors.email} />
                                                <Form.Control.Feedback type="invalid">
                                                    {errors.email?.message}
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                        </div>
                                        <div className="col-md-6">
                                            <Form.Group className="mb-3">
                                                <Form.Label htmlFor="state">State<span class="required">*</span></Form.Label>
                                                <Form.Select id="state" name="state" {...register('state')} isInvalid={!!errors.state}>
                                                    <option value="">Select</option>
                                                    <option value='Alabama'>Alabama</option>
                                                    <option value='Alaska'>Alaska</option>
                                                    <option value='Arizona'>Arizona</option>
                                                    <option value='Arkansas'>Arkansas</option>
                                                    <option value='California'>California</option>
                                                    <option value='Colorado'>Colorado</option>
                                                    <option value='Connecticut'>Connecticut</option>
                                                    <option value='Delaware'>Delaware</option>
                                                    <option value='Florida'>Florida</option>
                                                    <option value='Georgia'>Georgia</option>
                                                    <option value='Hawaii'>Hawaii</option>
                                                    <option value='Idaho'>Idaho</option>
                                                    <option value='Illinois'>Illinois</option>
                                                    <option value='Indiana'>Indiana</option>
                                                    <option value='Iowa'>Iowa</option>
                                                    <option value='Kansas'>Kansas</option>
                                                    <option value='Kentucky'>Kentucky</option>
                                                    <option value='Louisiana'>Louisiana</option>
                                                    <option value='Maine'>Maine</option>
                                                    <option value='Maryland'>Maryland</option>
                                                    <option value='Massachusetts'>Massachusetts</option>
                                                    <option value='Michigan'>Michigan</option>
                                                    <option value='Minnesota'>Minnesota</option>
                                                    <option value='Mississippi'>Mississippi</option>
                                                    <option value='Missouri'>Missouri</option>
                                                    <option value='Montana'>Montana</option>
                                                    <option value='Nebraska'>Nebraska</option>
                                                    <option value='Nevada'>Nevada</option>
                                                    <option value='New Hampshire'>New Hampshire</option>
                                                    <option value='New Jersey'>New Jersey</option>
                                                    <option value='New Mexico'>New Mexico</option>
                                                    <option value='New York'>New York</option>
                                                    <option value='North Carolina'>North Carolina</option>
                                                    <option value='North Dakota'>North Dakota</option>
                                                    <option value='Ohio'>Ohio</option>
                                                    <option value='Oklahoma'>Oklahoma</option>
                                                    <option value='Oregon'>Oregon</option>
                                                    <option value='Pennsylvania'>Pennsylvania</option>
                                                    <option value='Rhode Island'>Rhode Island</option>
                                                    <option value='South Carolina'>South Carolina</option>
                                                    <option value='South Dakota'>South Dakota</option>
                                                    <option value='Tennessee'>Tennessee</option>
                                                    <option value='Texas'>Texas</option>
                                                    <option value='Utah'>Utah</option>
                                                    <option value='Vermont'>Vermont</option>
                                                    <option value='Virginia'>Virginia</option>
                                                    <option value='Washington'>Washington</option>
                                                    <option value='West Virginia'>West Virginia</option>
                                                    <option value='Wisconsin'>Wisconsin</option>
                                                    <option value='Wyoming'>Wyoming</option>
                                                   
                                                </Form.Select>
                                                <Form.Control.Feedback type="invalid">
                                                    {errors.state?.message}
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                        </div>
                                        <div className="col-md-6">
                                            <Form.Group className="mb-3">
                                                <Form.Label htmlFor="fleet_size">Fleet size</Form.Label>
                                                <Form.Select id="fleet_size" name="fleet" {...register('fleet')} isInvalid={!!errors.fleet}>
                                                    <option value="">Select</option>
                                                    <option value="1">1</option>
                                                    <option value="2 to 5">2 to 5</option>
                                                    <option value="5 to 10">5 to 10</option>
                                                    <option value="Greater than 10">Greater than 10</option>
                                                </Form.Select>
                                                <Form.Control.Feedback type="invalid">
                                                    {errors.fleet?.message}
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                        </div>
                                        <p className='mb-0 disclaimer'>{readMoreText} <span onClick={() => setIsExpanded(!isExpanded)}>
                                            {isExpanded ? 'Read Less' : 'Read More'}
                                        </span></p>
                                        
                                        <p className="mt-3 mb-0 d-flex align-items-start">
                                            <input type="checkbox" id="privacy-checkbox" className="me-2"  required/>
                                            <label htmlFor="privacy-checkbox" className="me-2">
                                            I consent to receiving communications from Rentid via email, phone call, and SMS, including with the use of AI and automated dialing technologies, and I agree to Rentid’s <a href="terms" target="_blank" className="text-white">Terms of Service</a> and <a href="privacy" target="_blank" className="text-white">Privacy Policy</a>.
                                            </label>
                                           
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button type="submit" className='joinbtn' variant="primary">
                            Join The Waitlist
                            </Button>
                        </Modal.Footer>
                    </Form>
                </Modal>
               </div>
            </div>
          
        </section>
    );
}

export default SupportTeam;
