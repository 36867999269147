import React, { useState, useContext } from 'react'
import './Insurance.css'
import { Button, Form, Modal } from 'react-bootstrap';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import qs from 'qs';
import axios from 'axios';
import { UserContext } from '../../context/FormDataContext.js';
import { useNavigate } from 'react-router-dom';
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'   
import { API_ENDPOINT } from '../../Constant/Constant';


function Insurance() {
const navigate = useNavigate();
 const { updateUserFormData } = useContext(UserContext);
const [isExpanded, setIsExpanded] = useState(false);
const text = `By submitting this form, you consent to Rentid contacting you via SMS, phone, and email for information about our services, promotional offers, and updates. You understand and agree that Rentid may use automated technology including autodialing, AI generated messaging, and AI generated voice to contact you, and that consent is not a condition of purchase. Message frequency may vary, and message/data rates may apply. You can opt out at any time by following the instructions in our communications or by contacting us at contact@rentid.io.`;

const shortText = text.slice(0, 120) + '...'; // First 160 characters + ellipsis
const readMoreText = isExpanded ? text : shortText;

const [value, setValue] = useState()

const validationSchema = Yup.object().shape({
    firstName: Yup.string().required('First Name is required'),
    lastName: Yup.string().required('Last Name is required'),
    // companyName: Yup.string().required('Company Name is required'),
    phone: Yup.string()
    .required('Phone number is required'),
    // .min(10, 'Phone number must be at least 10 digits'),
    email: Yup.string().email('Email is invalid').required('Email is required'),
    state: Yup.string().required('State is required'),
    fleet: Yup.string().required('Fleet size is required')
});

    const { register, handleSubmit, formState: { errors }, reset, control } = useForm({
        resolver: yupResolver(validationSchema)
    });

    const onSubmit = async (data) => {        
        try {
            // Prepare the form data object
            const formData = {
                'waitlist[first_name]': data.firstName || 'First Name',
                'waitlist[last_name]': data.lastName || 'Last Name',
                'waitlist[company_name]': data.companyName || 'Company Name',
                'waitlist[phone_number]': data.phone || 'Phone Number',
                'waitlist[email_address]': data.email || 'Email Address',
                'waitlist[state]': data.state || 'State',
                'waitlist[fleet_size]': data.fleet || 'Fleet Size',
            };
    
            // Convert formData object to URL-encoded string
            const urlEncodedData = qs.stringify(formData);
    
            // Configure the request
            let config = {
                method: 'post',
                // url: 'https://api.getrentid.io/api/waitlist/submit-waitlist',
                url: `${API_ENDPOINT}/waitlist/submit-waitlist`,
                headers: { 
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
                data: urlEncodedData
            };
    
            // Make the request
            axios.request(config)
                .then((response) => {
                    console.log(JSON.stringify(response.data));
                    updateUserFormData(data.firstName, data.lastName,data.companyName, data.phone,data.email, data.state,data.fleet);
                    navigate("/beta-signup");
                    // window.location.href = '/thankyou';
                })
                .catch((error) => {
                    console.error('Error submitting form:', error);
                });
    
            // Reset the form
            handleClose();
            reset();
        } catch (error) {
            console.error('Error submitting form:', error);
        }
    };

    const [show,setShow]=useState(false);
    // show={show} onHide={handleClose}
    const handleModal=()=>{
        setShow(!show)
    }
    const handleClose=()=>{
        setShow(false)
    }
    return (
        <>
            <section id="coverages" className="coverage">
                <div className="container-fluid pe-0">
                    <div className="row">
                    
                        <div className="col-xl-6 col-lg-6 col-md-6 px-lg-0 px-3 coverage-heading">
                            <h2>
                                Primary A-rated commercial
                                auto insurance coverages
                            </h2>
                            <p className="sub-head pt-0 mb-3">Peace of mind exclusively available for your fleet</p>

                            <div className="row pt-5">
                                <div className="col-md-1 col-2 ps-0 pe-0">
                                    <img src="/img/state-mandatory.svg" className="img-fluid" width="48" alt="Mandatory Insurance" />
                                </div>
                                <div className="col-md-11 col-10 ps-0">
                                    <h4 className="pb-1 px-lg-3">State Mandatory Liability Insurance</h4>
                                    <p className='px-lg-3 covarege-paragraph'>
                                        Stay compliant and protected with liability insurance  covering bodily injury and
                                        property damage to others.
                                    </p>
                                </div>
                            </div>

                            <div className="row pt-5 pb-lg-3 pb-0">
                                <div className="col-md-1 col-2 ps-0 pe-0 ">
                                    <img src="/img/physical-damage.svg" className="img-fluid" width="48" alt="Physical Damage Insurance" />
                                </div>
                                <div className="col-md-11 col-10 ps-0">
                                    <h4 className="pb-1 px-lg-3">Physical Damage Insurance</h4>
                                    <p className='px-lg-3 covarege-paragraph'>
                                        Protect your vehicle from unexpected damage, including collisions, theft, and natural
                                        disasters.
                                    </p>
                                </div>
                            </div>

                            <div className="row waitlist pb-0">
                                <div className="col-md-12 px-1">
                                    <button type="button" className="btn btn-dark" onClick={handleModal}>Join Waitlist</button>
                                    <p className="sub-text">* For qualified vehicles only.</p>
                                </div>
                            </div>
                        </div>

                        <div className="col-xl-6 col-lg-6 col-md-6 coverage-image">
                        <img src="/img/coverage-logo.svg"  width="48" alt="" className="d-md-block d-none" />
                        <div class="rentid_car">
                        {/* <img src="/img/rentidcar.jpg" /> */}
                        </div>
                        </div>
                    </div>
                </div>
            </section>

            <section id="insurance" className="">
                <div className="container">
                    <div className="row align-items-center companies rounded-5 mx-0">
                        <div className="col-xl-5 col-lg-5 col-md-5">
                            <h3 className="mb-3">
                            Insurance Coverage 
                            for Rideshare & Delivery Drivers
                            </h3>
                            <p className="sub-head">Rent confidently to gig-economy drivers</p>
                            <p className="pt-3">
                                  Our insurance coverage ensures that both you and your drivers are protected, allowing you to confidently expand your rental business. Elevate your service and attract  more drivers by offering a secure and reliable  rental experience.
                            </p>
                        </div>

                        <div className="col-xl-7 col-lg-7 col-md-7 text-center company-logo">
                            <div className="row ">
                                <div className="col-md-4 col-6 text-center">
                                    <img src="/img/uber.webp" alt="Uber" />
                                </div>
                                <div className="col-md-4 col-6 text-center">
                                    <img src="/img/postmates.webp" alt="Postmates" />
                                </div>
                                <div className="col-md-4 col-6 text-center">
                                    <img src="/img/instacart.webp" alt="Instacart" />
                                </div>

                                <div className="col-md-4 col-6 text-center">
                                    <img src="/img/doordash.webp" alt="DoorDash" />
                                </div>
                                <div className="col-md-4 col-6 text-center">
                                    <img src="/img/lyr.webp" alt="Lyft" />
                                </div>
                                <div className="col-md-4 col-6 text-center">
                                    <img src="/img/ubereats.webp" alt="Uber Eats" />
                                </div>

                                <div className="col-md-4 col-6 text-center">
                                    <img src="/img/grubhub.webp" alt="Grubhub" />
                                </div>
                                <div className="col-md-4 col-6 text-center">
                                    <img src="/img/gopuff.webp" alt="Gopuff" />
                                </div>
                                <div className="col-md-4 col-12 text-center">
                                    <img src="/img/caviar.webp" alt="Caviar" style={{ width: '170px' }} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <Modal id="waitlist" className='joinlist' show={show} onHide={handleClose} centered>
                    <Modal.Header closeButton>
                        <Modal.Title>Join The Waitlist</Modal.Title>
                    </Modal.Header>
                    <Form onSubmit={handleSubmit(onSubmit)}>
                        <Modal.Body>
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="row">
                                        <div className="col-md-6">
                                            <Form.Group className="mb-3">
                                                <Form.Label htmlFor="first_name">First Name<span class="required">*</span></Form.Label>
                                                <Form.Control type="text" id="first_name" placeholder='Enter first name' name="waitlist[first_name]" {...register('firstName')}
                                                     />
                                                <Form.Control.Feedback type="invalid">
                                                    {errors.firstName?.message}
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                        </div>
                                        <div className="col-md-6">
                                            <Form.Group className="mb-3">
                                                <Form.Label htmlFor="last_name">Last Name<span class="required">*</span></Form.Label>
                                                <Form.Control type="text" id="last_name" placeholder='Enter last name' name="lastName" {...register('lastName')}
                                                    />
                                                <Form.Control.Feedback type="invalid">
                                                    {errors.lastName?.message}
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                        </div>
                                        <div className="col-md-12">
                                            <Form.Group className="mb-3">
                                                <Form.Label htmlFor="company_name">Company name (Optional)</Form.Label>
                                                <Form.Control type="text" id="company_name" placeholder='Enter company name' name="companyName" {...register('companyName')}
                                                  />
                                                    {/* isInvalid={!!errors.companyName} 
                                                <Form.Control.Feedback type="invalid">
                                                    {errors.companyName?.message}
                                                </Form.Control.Feedback> */}
                                            </Form.Group>
                                        </div>
                                        <div className="col-md-6">
                                       <Form.Group className="mb-3">
                                    <Form.Label htmlFor="phone_number">
                                        Phone number<span className="required">*</span>
                                    </Form.Label>
                                    <Controller
                                        name="phone"
                                        control={control}
                                        rules={{
                                            required: "Phone number is required",
                                            validate: (value) => value && value.length >= 10 || "Phone number must be valid",
                                        }}
                                        render={({ field }) => (
                                            <PhoneInput
                                                {...field}
                                                id="phone_number"
                                                limitMaxLength="10"
                                                defaultCountry="US"
                                                placeholder="(000) 000-000"
                                                onChange={(value) => field.onChange(value)} // Ensure React Hook Form value updates
                                                value={field.value || ''}
                                            />
                                        )}
                                    />
                                    {errors.phone && (
                                        <Form.Control.Feedback type="invalid" className="d-block">
                                            {errors.phone.message}
                                        </Form.Control.Feedback>
                                    )}
                                </Form.Group>

                                        </div>
                                        <div className="col-md-6">
                                            <Form.Group className="mb-3">
                                                <Form.Label htmlFor="email_address">Email address<span class="required">*</span></Form.Label>
                                                <Form.Control type="email" id="email_address" placeholder='Enter your email' name="email" {...register('email')}
                                                    isInvalid={!!errors.email} />
                                                <Form.Control.Feedback type="invalid">
                                                    {errors.email?.message}
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                        </div>
                                        <div className="col-md-6">
                                            <Form.Group className="mb-3">
                                                <Form.Label htmlFor="state">State<span class="required">*</span></Form.Label>
                                                <Form.Select id="state" name="state" {...register('state')} isInvalid={!!errors.state}>
                                                    <option value="">Select</option>
                                                    <option value='Alabama'>Alabama</option>
                                                    <option value='Alaska'>Alaska</option>
                                                    <option value='Arizona'>Arizona</option>
                                                    <option value='Arkansas'>Arkansas</option>
                                                    <option value='California'>California</option>
                                                    <option value='Colorado'>Colorado</option>
                                                    <option value='Connecticut'>Connecticut</option>
                                                    <option value='Delaware'>Delaware</option>
                                                    <option value='Florida'>Florida</option>
                                                    <option value='Georgia'>Georgia</option>
                                                    <option value='Hawaii'>Hawaii</option>
                                                    <option value='Idaho'>Idaho</option>
                                                    <option value='Illinois'>Illinois</option>
                                                    <option value='Indiana'>Indiana</option>
                                                    <option value='Iowa'>Iowa</option>
                                                    <option value='Kansas'>Kansas</option>
                                                    <option value='Kentucky'>Kentucky</option>
                                                    <option value='Louisiana'>Louisiana</option>
                                                    <option value='Maine'>Maine</option>
                                                    <option value='Maryland'>Maryland</option>
                                                    <option value='Massachusetts'>Massachusetts</option>
                                                    <option value='Michigan'>Michigan</option>
                                                    <option value='Minnesota'>Minnesota</option>
                                                    <option value='Mississippi'>Mississippi</option>
                                                    <option value='Missouri'>Missouri</option>
                                                    <option value='Montana'>Montana</option>
                                                    <option value='Nebraska'>Nebraska</option>
                                                    <option value='Nevada'>Nevada</option>
                                                    <option value='New Hampshire'>New Hampshire</option>
                                                    <option value='New Jersey'>New Jersey</option>
                                                    <option value='New Mexico'>New Mexico</option>
                                                    <option value='New York'>New York</option>
                                                    <option value='North Carolina'>North Carolina</option>
                                                    <option value='North Dakota'>North Dakota</option>
                                                    <option value='Ohio'>Ohio</option>
                                                    <option value='Oklahoma'>Oklahoma</option>
                                                    <option value='Oregon'>Oregon</option>
                                                    <option value='Pennsylvania'>Pennsylvania</option>
                                                    <option value='Rhode Island'>Rhode Island</option>
                                                    <option value='South Carolina'>South Carolina</option>
                                                    <option value='South Dakota'>South Dakota</option>
                                                    <option value='Tennessee'>Tennessee</option>
                                                    <option value='Texas'>Texas</option>
                                                    <option value='Utah'>Utah</option>
                                                    <option value='Vermont'>Vermont</option>
                                                    <option value='Virginia'>Virginia</option>
                                                    <option value='Washington'>Washington</option>
                                                    <option value='West Virginia'>West Virginia</option>
                                                    <option value='Wisconsin'>Wisconsin</option>
                                                    <option value='Wyoming'>Wyoming</option>
                                                   
                                                </Form.Select>
                                                <Form.Control.Feedback type="invalid">
                                                    {errors.state?.message}
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                        </div>
                                        <div className="col-md-6">
                                            <Form.Group className="mb-3">
                                                <Form.Label htmlFor="fleet_size">Fleet size</Form.Label>
                                                <Form.Select id="fleet_size" name="fleet" {...register('fleet')} isInvalid={!!errors.fleet}>
                                                    <option value="">Select</option>
                                                    <option value="1">1</option>
                                                    <option value="2 to 5">2 to 5</option>
                                                    <option value="5 to 10">5 to 10</option>
                                                    <option value="Greater than 10">Greater than 10</option>
                                                </Form.Select>
                                                <Form.Control.Feedback type="invalid">
                                                    {errors.fleet?.message}
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                        </div>
                                        <p className='mb-0 disclaimer'>{readMoreText} <span onClick={() => setIsExpanded(!isExpanded)}>
                                            {isExpanded ? 'Read Less' : 'Read More'}
                                        </span></p>
                                        
                                        <p className="mt-3 mb-0 d-flex align-items-start">
                                            <input type="checkbox" id="privacy-checkbox" className="me-2"  required/>
                                            <label htmlFor="privacy-checkbox" className="me-2">
                                            I consent to receiving communications from Rentid via email, phone call, and SMS, including with the use of AI and automated dialing technologies, and I agree to Rentid’s <a href="terms" target="_blank" className="text-white">Terms of Service</a> and <a href="privacy" target="_blank" className="text-white">Privacy Policy</a>.
                                            </label>
                                           
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button type="submit" className='joinbtn' variant="primary">
                            Join The Waitlist
                            </Button>
                        </Modal.Footer>
                    </Form>
                </Modal>
                
            </section>
        </>
    )
}

export default Insurance