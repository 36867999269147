import React from 'react';
import Lottie from 'react-lottie';
import animationData from './animation/system-solid-31-check-hover-check.json'

const AnimationComponent = () => {
    // Lottie animation options
    const defaultOptions = {
      loop: false,          // Whether the animation should loop
      autoplay: true,      // Whether the animation should play automatically
      animationData: animationData, // Your downloaded Lottie JSON file
    };
  
    return (
      <div>
        <Lottie options={defaultOptions} height={80} width={80} />
      </div>
    );
  };
  
  export default AnimationComponent;
  