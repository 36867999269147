
import './SolutionSection.css'
import React, { useState, useContext } from 'react'
import { Button, Form, Modal } from 'react-bootstrap';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import qs from 'qs';
import axios from 'axios';
import { UserContext } from '../../context/FormDataContext.js';
import { useNavigate } from 'react-router-dom';
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input';
import { API_ENDPOINT } from '../../Constant/Constant';

function SolutionSection() {
    const [show,setShow]=useState(false);
    const navigate = useNavigate();
    const { updateUserFormData } = useContext(UserContext);
    const [isExpanded, setIsExpanded] = useState(false);
    const text = `By submitting this form, you consent to Rentid contacting you via SMS, phone, and email for information about our services, promotional offers, and updates. You understand and agree that Rentid may use automated technology including autodialing, AI generated messaging, and AI generated voice to contact you, and that consent is not a condition of purchase. Message frequency may vary, and message/data rates may apply. You can opt out at any time by following the instructions in our communications or by contacting us at contact@rentid.io.`;

    const shortText = text.slice(0, 120) + '...'; // First 160 characters + ellipsis
    const readMoreText = isExpanded ? text : shortText;
    const [value, setValue] = useState();

const validationSchema = Yup.object().shape({
    firstName: Yup.string().required('First Name is required'),
    lastName: Yup.string().required('Last Name is required'),
    // companyName: Yup.string().required('Company Name is required'),
    phone: Yup.string()
    .required('Phone number is required'),
    // .min(10, 'Phone number must be at least 10 digits'),
    email: Yup.string().email('Email is invalid').required('Email is required'),
    state: Yup.string().required('State is required'),
    fleet: Yup.string().required('Fleet size is required')
});

    const { register, handleSubmit, formState: { errors }, reset, control } = useForm({
        resolver: yupResolver(validationSchema)
    });

    const onSubmit = async (data) => {        
        try {
            // Prepare the form data object
            const formData = {
                'waitlist[first_name]': data.firstName || 'First Name',
                'waitlist[last_name]': data.lastName || 'Last Name',
                'waitlist[company_name]': data.companyName || 'Company Name',
                'waitlist[phone_number]': data.phone || 'Phone Number',
                'waitlist[email_address]': data.email || 'Email Address',
                'waitlist[state]': data.state || 'State',
                'waitlist[fleet_size]': data.fleet || 'Fleet Size',
            };
    
            // Convert formData object to URL-encoded string
            const urlEncodedData = qs.stringify(formData);
    
            // Configure the request
            let config = {
                method: 'post',
                // url: 'https://api.getrentid.io/api/waitlist/submit-waitlist',
                url: `${API_ENDPOINT}/waitlist/submit-waitlist`,
                headers: { 
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
                data: urlEncodedData
            };
    
            // Make the request
            axios.request(config)
                .then((response) => {
                    console.log(JSON.stringify(response.data));
                    updateUserFormData(data.firstName, data.lastName,data.companyName, data.phone,data.email, data.state,data.fleet);
                    navigate("/beta-signup");
                    // window.location.href = '/thankyou';
                })
                .catch((error) => {
                    console.error('Error submitting form:', error);
                });
    
            // Reset the form
            handleClose();
            reset();
        } catch (error) {
            console.error('Error submitting form:', error);
        }
    };


    const handleModal=()=>{
        setShow(!show)
    }
    const handleClose=()=>{
        setShow(false)
    }


    return (
        <>
            <section id="eleven" className="perfect-solution">
                <div className="container">
                    <div className="row align-items-center reverse-columns" id='fleet'>
                        <div className="col-xl-5 col-lg-5 col-md-5 px-0 fleet-description">
                            <h6 className="">THE PERFECT SOLUTION </h6>
                            <h3>Fleet Sizes</h3>
                            <p className="">
                                Whether you're just getting started or have years of experience...we help
                                fleets ranging from one to hundreds of vehicles, across multiple branches.
                            </p>
                            <div className="solution-img-sec">
								     <img src="/img/obi-pixel.png" alt="" class="img-fluid"/>
								 </div>
                            <ul className="fleet">
                                <li>  <img src="/img/check-icon.svg" alt="" /> 5 Vehicles</li>
                                <li> <img src="/img/check-icon.svg" alt="" />5 - 25 Vehicles</li>
                                <li> <img src="/img/check-icon.svg" alt="" />25 - 100 Vehicles</li>
                                <li> <img src="/img/check-icon.svg" alt="" />100+ Vehicles</li>
                            </ul>
                        </div>

                        <div className="col-xl-7 col-lg-7 col-md-7 pb-lg-0 perfect-section-glry">
                            <div className="row">
							     <div className="col-xl-4 col-lg-4 col-md-4 pb-lg-0 perfection-inner-image1">
								     <img src="/img/obi-pixel.png" alt="" class="img-fluid"/>
								 </div>
								 
								 <div className="col-xl-4 col-lg-4 col-md-4 pb-lg-0 perfection-inner-image">
								      <img src="/img/connor.png" alt="" class="img-fluid"/>
								      <img src="/img/benjamin.png" alt="" class="img-fluid"/>
								 </div>
								 <div className="col-xl-4 col-lg-4 col-md-4 pb-lg-0 perfection-inner-image1">
								     <img src="/img/grant-richie.png" alt="" class="img-fluid"/>
								 </div>
							</div>
                        </div>
                    </div>

                    <div className="row align-items-center partners"  id='partners'>
                        <div className="col-xl-6 col-lg-6 col-md-6 px-0">
                            <div class="row">
                             <div class="col-md-6 partner-img perfection-inner-image2">
                             <img src="/img/Base-carousel-milti-image.png" className="w-100" alt="base" />
                             </div>
                             <div class="col-md-6 partner-img perfection-inner-image2">
                             <img src="/img/enhanced-image.png" className="w-100" alt="base" />
                            </div>
                            <div class="col-md-6 lets-partner perfection-inner-image2">
                            <img src="/img/Adobe-stock.png" className="w-100" alt="base" />
                              </div>
                              <div class="col-md-6 lets-partner perfection-inner-image2">
                              <img src="/img/adobestock.png" className="w-100" alt="base" />
                               </div>
                            </div>
                          
                        </div>
                        <div className="col-xl-6 col-lg-6 col-md-6 solution-inner-sec">
                            <div className="rightcard ">
                                <h6 className="">SOMETHING FOR EVERYONE </h6>
                                <h3>Let’s Partner</h3>
                                <p className="">
                                    We're excited to partner with entrepreneurs who are passionate about
                                    enhancing the customer experience and committed to generating revenue, efficiently.
                                </p>
                                <div class="partner-img-sec">
                             <img src="/img/Base-carousel-milti-image.png" className="w-100" alt="base" />
                             </div>
                                <ul className="fleet">
                                    <li> <img src="/img/check-icon.svg" alt="" /> Car Rental Agencies</li>
                                    <li> <img src="/img/check-icon.svg" alt="" /> Service & Repair / Auto body</li>
                                    <li> <img src="/img/check-icon.svg" alt="" /> Marketplace Hosts & Co-Hosts</li>
                                    <li> <img src="/img/check-icon.svg" alt="" /> Pre-owned and New Auto Dealerships</li>
                                    <li> <img src="/img/check-icon.svg" alt="" /> Hospitality and Parking Management Industries</li>
                                </ul>
                                <div className="col-md-12 px-1 mt-3">
                                    <button type="button" id="join_wait" className="btn btn-dark" onClick={handleModal}>Join The Waitlist</button>
                                </div>

                                <Modal id="waitlist" className='joinlist' show={show} onHide={handleClose} centered>
                    <Modal.Header closeButton>
                        <Modal.Title>Join The Waitlist</Modal.Title>
                    </Modal.Header>
                    <Form onSubmit={handleSubmit(onSubmit)}>
                        <Modal.Body>
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="row">
                                        <div className="col-md-6">
                                            <Form.Group className="mb-3">
                                                <Form.Label htmlFor="first_name">First Name<span class="required">*</span></Form.Label>
                                                <Form.Control type="text" id="first_name" placeholder='Enter first name' name="waitlist[first_name]" {...register('firstName')}
                                                     />
                                                <Form.Control.Feedback type="invalid">
                                                    {errors.firstName?.message}
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                        </div>
                                        <div className="col-md-6">
                                            <Form.Group className="mb-3">
                                                <Form.Label htmlFor="last_name">Last Name<span class="required">*</span></Form.Label>
                                                <Form.Control type="text" id="last_name" placeholder='Enter last name' name="lastName" {...register('lastName')}
                                                    />
                                                <Form.Control.Feedback type="invalid">
                                                    {errors.lastName?.message}
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                        </div>
                                        <div className="col-md-12">
                                            <Form.Group className="mb-3">
                                                <Form.Label htmlFor="company_name">Company name (Optional)</Form.Label>
                                                <Form.Control type="text" id="company_name" placeholder='Enter company name' name="companyName" {...register('companyName')}
                                                  />
                                                    isInvalid={!!errors.companyName} 
                                                <Form.Control.Feedback type="invalid">
                                                    {errors.companyName?.message}
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                        </div>
                                        <div className="col-md-6">
                                       <Form.Group className="mb-3">
                                    <Form.Label htmlFor="phone_number">
                                        Phone number<span className="required">*</span>
                                    </Form.Label>
                                    <Controller
                                        name="phone"
                                        control={control}
                                        rules={{
                                            required: "Phone number is required",
                                            validate: (value) => value && value.length >= 10 || "Phone number must be valid",
                                        }}
                                        render={({ field }) => (
                                            <PhoneInput
                                                {...field}
                                                id="phone_number"
                                                limitMaxLength="10"
                                                defaultCountry="US"
                                                placeholder="(000) 000-000"
                                                onChange={(value) => field.onChange(value)} // Ensure React Hook Form value updates
                                                value={field.value || ''}
                                            />
                                        )}
                                    />
                                    {errors.phone && (
                                        <Form.Control.Feedback type="invalid" className="d-block">
                                            {errors.phone.message}
                                        </Form.Control.Feedback>
                                    )}
                                </Form.Group>

                                        </div>
                                        <div className="col-md-6">
                                            <Form.Group className="mb-3">
                                                <Form.Label htmlFor="email_address">Email address<span class="required">*</span></Form.Label>
                                                <Form.Control type="email" id="email_address" placeholder='Enter your email' name="email" {...register('email')}
                                                    isInvalid={!!errors.email} />
                                                <Form.Control.Feedback type="invalid">
                                                    {errors.email?.message}
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                        </div>
                                        <div className="col-md-6">
                                            <Form.Group className="mb-3">
                                                <Form.Label htmlFor="state">State<span class="required">*</span></Form.Label>
                                                <Form.Select id="state" name="state" {...register('state')} isInvalid={!!errors.state}>
                                                    <option value="">Select</option>
                                                    <option value='Alabama'>Alabama</option>
                                                    <option value='Alaska'>Alaska</option>
                                                    <option value='Arizona'>Arizona</option>
                                                    <option value='Arkansas'>Arkansas</option>
                                                    <option value='California'>California</option>
                                                    <option value='Colorado'>Colorado</option>
                                                    <option value='Connecticut'>Connecticut</option>
                                                    <option value='Delaware'>Delaware</option>
                                                    <option value='Florida'>Florida</option>
                                                    <option value='Georgia'>Georgia</option>
                                                    <option value='Hawaii'>Hawaii</option>
                                                    <option value='Idaho'>Idaho</option>
                                                    <option value='Illinois'>Illinois</option>
                                                    <option value='Indiana'>Indiana</option>
                                                    <option value='Iowa'>Iowa</option>
                                                    <option value='Kansas'>Kansas</option>
                                                    <option value='Kentucky'>Kentucky</option>
                                                    <option value='Louisiana'>Louisiana</option>
                                                    <option value='Maine'>Maine</option>
                                                    <option value='Maryland'>Maryland</option>
                                                    <option value='Massachusetts'>Massachusetts</option>
                                                    <option value='Michigan'>Michigan</option>
                                                    <option value='Minnesota'>Minnesota</option>
                                                    <option value='Mississippi'>Mississippi</option>
                                                    <option value='Missouri'>Missouri</option>
                                                    <option value='Montana'>Montana</option>
                                                    <option value='Nebraska'>Nebraska</option>
                                                    <option value='Nevada'>Nevada</option>
                                                    <option value='New Hampshire'>New Hampshire</option>
                                                    <option value='New Jersey'>New Jersey</option>
                                                    <option value='New Mexico'>New Mexico</option>
                                                    <option value='New York'>New York</option>
                                                    <option value='North Carolina'>North Carolina</option>
                                                    <option value='North Dakota'>North Dakota</option>
                                                    <option value='Ohio'>Ohio</option>
                                                    <option value='Oklahoma'>Oklahoma</option>
                                                    <option value='Oregon'>Oregon</option>
                                                    <option value='Pennsylvania'>Pennsylvania</option>
                                                    <option value='Rhode Island'>Rhode Island</option>
                                                    <option value='South Carolina'>South Carolina</option>
                                                    <option value='South Dakota'>South Dakota</option>
                                                    <option value='Tennessee'>Tennessee</option>
                                                    <option value='Texas'>Texas</option>
                                                    <option value='Utah'>Utah</option>
                                                    <option value='Vermont'>Vermont</option>
                                                    <option value='Virginia'>Virginia</option>
                                                    <option value='Washington'>Washington</option>
                                                    <option value='West Virginia'>West Virginia</option>
                                                    <option value='Wisconsin'>Wisconsin</option>
                                                    <option value='Wyoming'>Wyoming</option>
                                                   
                                                </Form.Select>
                                                <Form.Control.Feedback type="invalid">
                                                    {errors.state?.message}
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                        </div>
                                        <div className="col-md-6">
                                            <Form.Group className="mb-3">
                                                <Form.Label htmlFor="fleet_size">Fleet size</Form.Label>
                                                <Form.Select id="fleet_size" name="fleet" {...register('fleet')} isInvalid={!!errors.fleet}>
                                                    <option value="">Select</option>
                                                    <option value="1">1</option>
                                                    <option value="2 to 5">2 to 5</option>
                                                    <option value="5 to 10">5 to 10</option>
                                                    <option value="Greater than 10">Greater than 10</option>
                                                </Form.Select>
                                                <Form.Control.Feedback type="invalid">
                                                    {errors.fleet?.message}
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                        </div>
                                        <p className='mb-0 disclaimer'>{readMoreText} <span onClick={() => setIsExpanded(!isExpanded)}>
                                            {isExpanded ? 'Read Less' : 'Read More'}
                                        </span></p>
                                        
                                        <p className="mt-3 mb-0 d-flex align-items-start">
                                            <input type="checkbox" id="privacy-checkbox" className="me-2"  required/>
                                            <label htmlFor="privacy-checkbox" className="me-2">
                                            I consent to receiving communications from Rentid via email, phone call, and SMS, including with the use of AI and automated dialing technologies, and I agree to Rentid’s <a href="terms" target="_blank" className="text-white">Terms of Service</a> and <a href="privacy" target="_blank" className="text-white">Privacy Policy</a>.
                                            </label>
                                           
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button type="submit" className='joinbtn' variant="primary">
                            Join The Waitlist
                            </Button>
                        </Modal.Footer>
                    </Form>
                </Modal>

                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default SolutionSection