import React, { useEffect, useRef } from 'react';

const ScrollingMarquee = () => {
  const marqueeContainerRef = useRef(null);
  const marqueeContentRef = useRef(null);

  useEffect(() => {
    const marqueeContainer = marqueeContainerRef.current;
    const marqueeContent = marqueeContentRef.current;

    // Clone the marquee content to ensure continuous scrolling
    const marqueeClone = marqueeContent.cloneNode(true);
    marqueeContainer.appendChild(marqueeClone);

    let containerWidth = marqueeContainer.offsetWidth;
    let contentWidth = marqueeContent.offsetWidth;

    // Starting position of the marquee
    let position = containerWidth;

    const animate = () => {
      position -= 1.7; // Speed of scrolling (increase or decrease this value for faster/slower speed)

      // Move the marquee content by adjusting its left position
      marqueeContent.style.transform = `translateX(${position}px)`;

      // Reset position when the content has fully moved out of view
      if (position <= -contentWidth) {
        position = containerWidth;
      }

      requestAnimationFrame(animate);
    };

    animate(); // Start the animation
  }, []);

  return (
    <div
      ref={marqueeContainerRef}
      style={{
        width: '100%',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
      }}
    >
      <div ref={marqueeContentRef} style={{ display: 'inline-block' }} className='scrolling-text-sec'>
        <span className='marquess'>
            It’s time to get rentid. Start for free. Earn more than ever before. Take control of your business. 
        </span>

        <span className='marquess'>
            It’s time to get rentid. Start for free. Earn more than ever before. Take control of your business. 
        </span>

        <span className='marquess'>
            It’s time to get rentid. Start for free. Earn more than ever before. Take control of your business. 
        </span>

        <span className='marquess'>
            It’s time to get rentid. Start for free. Earn more than ever before. Take control of your business. 
        </span>

        <span className='marquess'>
            It’s time to get rentid. Start for free. Earn more than ever before. Take control of your business. 
        </span>

        <span className='marquess'>
            It’s time to get rentid. Start for free. Earn more than ever before. Take control of your business. 
        </span>

        <span className='marquess'>
            It’s time to get rentid. Start for free. Earn more than ever before. Take control of your business. 
        </span>

        <span className='marquess'>
            It’s time to get rentid. Start for free. Earn more than ever before. Take control of your business. 
        </span>

        <span className='marquess'>
            It’s time to get rentid. Start for free. Earn more than ever before. Take control of your business. 
        </span>

        <span className='marquess'>
            It’s time to get rentid. Start for free. Earn more than ever before. Take control of your business. 
        </span>

        <span className='marquess'>
            It’s time to get rentid. Start for free. Earn more than ever before. Take control of your business. 
        </span>

        <span className='marquess'>
            It’s time to get rentid. Start for free. Earn more than ever before. Take control of your business. 
        </span>

        <span className='marquess'>
            It’s time to get rentid. Start for free. Earn more than ever before. Take control of your business. 
        </span>

        <span className='marquess'>
            It’s time to get rentid. Start for free. Earn more than ever before. Take control of your business. 
        </span>

        <span className='marquess'>
            It’s time to get rentid. Start for free. Earn more than ever before. Take control of your business. 
        </span>
      
      </div>
    </div>
  );
};

export default ScrollingMarquee;