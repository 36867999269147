import React, { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Button, Container, Grid, Typography } from '@mui/material';
import logo from '../../assets/images/logo_new.png'
import arrow_down from "../../assets/images/arrow-down-01.png";
import rentdirect from "./assets/images/rent-direct-logo.svg";
import direct1 from "../../assets/images/direct1.png";
import { Link } from 'react-router-dom';
// import ReactFlow, { Background,  addEdge } from 'reactflow';
import 'reactflow/dist/style.css';
import Anima1 from './RendDirectanimation'
import Header from '../Header/Header';
import crm from "../../assets/images/CRM.webp";
import CalendarSync from "../../assets/images/CalendarSync.webp";
import telemetics from "../../assets/images/telemetics.webp";
import notification from "../../assets/images/Notifications.webp";
import AIChat from "../../assets/images/AIChat.webp";
import style1 from "./style1.css";
import RentHeader from '../RentHeader/RentHeader';
import BetaForm from '../BetaForm';

const RentDirect2 = () => {
  // const [nodes, setNodes] = React.useState(initialNodes);
  // const [edges, setEdges] = React.useState(initialEdges);
  const navigate = useNavigate();

  // const onConnect = useCallback((params) => setEdges((eds) => addEdge(params, eds)), []);

  return (
    <>
       
      <Box
        sx={{
          background: 'linear-gradient(180deg, #082C72 0%, #001132 55%, #000000 100%)',
          height: 'auto',
          width: '100%',

        }}
      >
          
        <Container>
        <RentHeader/>
       
          {/* Banner section */}
          <Box sx={{ marginTop: '5%' , }}>
            <Grid container spacing={5}>
              {/* Left side content */}
              <Grid item md={5} xs={12}>
                <Box style={{paddingTop:'22%', paddingBottom:'160px'}}>
                  <img src={rentdirect} alt="rentdirect" />
                  <Typography sx={{
                    fontSize: '17px',
                    paddingTop: '5%',
                    fontFamily: 'Manrope',
                    fontWeight: 700,
                    color: '#FFFFFF',
                  }}>
                    ALL IN ONE DIGITAL RENTAL
                  </Typography>
                  <Typography sx={{
                    fontSize: '36px',
                    paddingTop: '5%',
                    fontFamily: 'Manrope',
                    fontWeight: 600,
                    color: '#FFFFFF',
                    lineHeight: '45px',
                  }}>
                    From Booking engine to fleet management and everything <span style={{
                      background: 'linear-gradient(90.02deg,#b9fcf4 3.13%,#e9c9fc 66.57%)', 
                      color: "black", 
                      borderRadius: '4px', 
                      padding: '2px 4px',
                      display: 'inline-block',
                      lineHeight:'37px',
                      paddingBottom:'5px',
                      marginTop:'9px',
                    }}>
                      in between
                    </span>
                  </Typography>
                  <Typography sx={{
                   marginTop:'2%',
                    fontSize: '19px',
                    paddingTop: '5%',
                    fontFamily: 'Manrope',
                    fontWeight: 300,
                    color: '#FFFFFF',
                    lineHeight: '25px',
                  }}>
                    The <span style={{ fontWeight: 600 }}>rent</span>direct™ frictionless ecosystem manages pre-trip, on-trip, and post-trip details so you can focus on building relationships with guests.
                  </Typography>
                </Box>
              </Grid>

              {/* Right side flowchart */}
              <Grid item md={7} xs={12}>
            <Anima1 />
        
              </Grid>
            
            </Grid>


          </Box>
        </Container>
      </Box>



    {/* next section start from here */}
      {/* <Container sx={{marginTop:'4%'}}>
     
         <Grid container sx={{paddingTop:'6%', display:'flex', alignItems:'center'}}>

            <Grid item md={6}>
              <Box id="crm" sx={{height:'auto', textAlign:'center'}}>
                <Typography sx={{fontSize:'40px', color:'#404854',fontWeight:600}}>CRM</Typography>
                <img src={crm} width='250px'  alt='crm'/>
                </Box>
                </Grid>
                <Grid item md={6}>
                <Typography sx={{fontSize:'16px ', color:'#696969',fontWeight:300, lineHeight:'30px' , marginTop:'35px'}}>Track & nurture your contracts with integrated CRM database.Customer Relationship Management (CRM) plays a crucial role in the rental marketing industry by streamlining the management of tenant and property owner interactions. With a CRM, rental businesses can efficiently track inquiries, automate follow-ups, and maintain a centralized database of leads and tenants.</Typography>
                </Grid>
            </Grid>


        <Grid container sx={{marginTop:'6%'}}>
            <Grid item md={6}>
              <Box id="calender"  sx={{height:'auto', textAlign:'center'}}>
                <Typography sx={{fontSize:'40px', color:'#404854',fontWeight:600}}>Calender Sync</Typography>
                <img src={CalendarSync} width='39%' alt='' />
                </Box>
                </Grid>
                <Grid item md={6}>
                <Typography sx={{fontSize:'16px ', color:'#696969',fontWeight:300, lineHeight:'30px'}}>we are committed to maintaining a respectful and fair environment for all users. Violations of rental policies, such as misrepresentation of property details, unauthorized subletting, failure to adhere to agreed payment terms, or property misuse, are strictly prohibited. Such actions may result in the suspension or termination of user accounts and potential legal consequences. We encourage open communication between renters and property owners to resolve disputes amicably and ensure compliance with our guidelines. If you encounter any violations, please report them promptly to our support team for assistance.</Typography>
                </Grid>
            </Grid>

            


            <Grid container sx={{marginTop:'6%'}}>
            <Grid item md={6}>
            <Box id="telemetics"  sx={{height:'auto', textAlign:'center', }}>
            <Typography  sx={{fontSize:'40px', color:'#404854',fontWeight:600}}>Telematics</Typography>
            <img src={telemetics} alt='' width='39%' />
            </Box>
            </Grid>

            <Grid item md={6}>
            <Typography  sx={{fontSize:'16px ', color:'#696969',fontWeight:300, lineHeight:'30px'}}>Telematics is a powerful technology that combines telecommunications and informatics to provide real-time data and insights, transforming the rental marketing industry. By integrating telematics into rental operations, businesses can track property performance, monitor asset usage, and optimize maintenance schedules. For vehicle or equipment rentals, telematics enables GPS tracking, usage analytics, and condition monitoring, ensuring efficient fleet management and improved customer service.</Typography>
            </Grid>
            </Grid>


            <Grid container sx={{marginTop:'6%'}}>
          

            <Grid item md={6}>
          
            <Box id="ai" sx={{height:'auto', textAlign:'center'}}>
            <Typography sx={{fontSize:'35px', color:'#404854',fontWeight:600}}>AI</Typography>
            <img src={AIChat} alt="" width='39%' />
            </Box>
            </Grid>
            
            <Grid item md={6} >
            <Typography sx={{fontSize:'16px ', color:'#696969',fontWeight:300, lineHeight:'30px'}}>Artificial Intelligence (AI) is revolutionizing the rental marketing industry by offering smarter, faster, and more efficient solutions. AI-powered chatbots can handle customer inquiries 24/7, providing instant support and boosting lead conversion rates. Advanced algorithms analyze market trends and customer preferences to recommend the most suitable properties or rental options, enhancing personalization. AI can also automate tasks like generating property listings, optimizing pricing strategies, and predicting customer needs based on historical data. By integrating AI, rental businesses can improve operational efficiency, enhance customer experience, and gain a competitive edge in a rapidly evolving market.</Typography>
            </Grid>
            </Grid>

            <Grid container sx={{marginTop:'6%'}}>
          

          <Grid item md={6}>
        
          <Box id="notification" sx={{height:'auto', textAlign:'center'}}>
          <Typography sx={{fontSize:'35px', color:'#404854',fontWeight:600}}>Notification</Typography>
          <img src={notification} alt="" width='39%' />
          </Box>
          </Grid>
          
          <Grid item md={6} >
          <Typography sx={{fontSize:'16px ', color:'#696969',fontWeight:300, lineHeight:'30px'}}>Notification  the rental marketing industry by offering smarter, faster, and more efficient solutions. AI-powered chatbots can handle customer inquiries 24/7, providing instant support and boosting lead conversion rates. Advanced algorithms analyze market trends and customer preferences to recommend the most suitable properties or rental options, enhancing personalization. AI can also automate tasks like generating.</Typography>
          </Grid>
          </Grid>

          <Grid container sx={{marginTop:'6%'}}>
          

          <Grid item md={6}>
        
          <Box id="maintenaince" sx={{height:'auto', textAlign:'center'}}>
          <Typography sx={{fontSize:'35px', color:'#404854',fontWeight:600}}>Maintenaince</Typography>
          <img src={AIChat} alt="" width='39%' />
          </Box>
          </Grid>
          
          <Grid item md={6} >
          <Typography sx={{fontSize:'16px ', color:'#696969',fontWeight:300, lineHeight:'30px'}}>Notification  the rental marketing industry by offering smarter, faster, and more efficient solutions. AI-powered chatbots can handle customer inquiries 24/7, providing instant support and boosting lead conversion rates. Advanced algorithms analyze market trends and customer preferences to recommend the most suitable properties or rental options, enhancing personalization. AI can also automate tasks like generating.</Typography>
          </Grid>
          </Grid>

      </Container> */}
<BetaForm/>
    </>
  );
};

export default RentDirect2;
