import React from 'react';
import './SupportTeam.css';

const AnimationCards = () => {
  const cards = [
    { id: 1, image: '/img/file-01.svg', heading: 'Violations Recovery', text: 'When rental incidentals happen, we’re there to recover what’s owed.' },
    { id: 2, image: 'img/certificate-01.svg', heading: 'Resolution Center', text: 'One-stop solutions hub for seamless host-to-renter relationship management.' },
    { id: 3, image: 'img/tow-truck1 (2).svg', heading: 'Roadside Assistance', text: 'Superior support designed for hosts and their renters integrated for every rental.' },
    { id: 4, image: 'img/elements.svg', heading: 'Smart Automations', text: 'Streamlined operations & workflows that save time and reduce manual effort.' },
    { id: 5, image: 'img/web-protection1.svg', heading: 'Simplified Tolls & Tickets', text: 'Effortless & seamless automated billing to ensure uninterrupted guest plans.' },
    { id: 6, image: 'img/smart-phone-01.svg', heading: 'Branded Experiences', text: 'Build trust with renters to strengthen relationships and drive repeat business.' },
    { id: 7, image: 'img/headset.svg', heading: 'Educational Resource Center', text: 'Helpful tools, guides, and support, ensuring smoother trip experiences.' },
    { id: 8, image: 'img/mentoring.svg', heading: 'Customer Support', text: '24/7 Automated and live tech and trip support for hosts & guests.' },
    { id: 9, image: 'img/student-card.svg', heading: 'Payment & Invoicing', text: 'Digitally secure payments and easy-to-use billing platform for every rental.' },
  ];

  return (
    <div className="office-animation-cards">
      <div className="carousel-container mt-2">
        <div className="carousel">
      
          {cards.map((card) => (
            <div key={card.id} className="card">
              <img src={card.image} alt={card.heading} className="image" />
              <h3 className="heading">{card.heading}</h3>
              <p className="text-para">{card.text}</p>
            </div>
          ))}
          {/* Duplicated cards for seamless looping */}
          {cards.map((card) => (
            <div key={`duplicate-${card.id}`} className="card">
              <img src={card.image} alt={card.heading} className="image" />
              <h3 className="heading">{card.heading}</h3>
              <p className="text-para">{card.text}</p>
            </div>
          ))}
        </div>
        <div className="fade-right"></div>
      </div>
    </div>
  );
};

export default AnimationCards;
