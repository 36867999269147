import React, { useState } from 'react';
import { Box, Button, Container, Grid, IconButton, Drawer, Typography } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu'; // Import the menu icon
import CloseIcon from '@mui/icons-material/Close'; // Import the close icon
import logo from '../../assets/images/logo_new.png';
import arrow_down from "../../assets/images/arrow-down-01.png";
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import BetaForm from '../BetaForm';
import guestimg from '../../assets/images/user-2.svg';
import hostimg from '../../assets/images/briefcase.svg';
import './RentStyle.css'
const RentHeader = () => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const navigate = useNavigate();
  const handleLogin = () => {
    window.open('https://getrentid.io/marketing/login', '_blank');
  };


  const handleApplybtn =()=> {
    navigate('/beta-signup')
  }

  

  return (
    <>
      <Grid
        container
        alignItems="center"
        justifyContent="space-between"
        sx={{ paddingY: '2%' }}
      >
        {/* Left - Logo */}
        <Grid item xs={6} md={6}>
          <Box>
            <Link to='/'>
              {/* <img src={logo} alt="logo" style={{ height: 'auto', width: '117px' }} /> */}
              <h1 className="sitename">
                <img src="/img/Rentid-white.svg" alt="desktop logo" className="desktop-logo" />
                <img src="/img/mobile-logo.svg" alt="mobile logo" className="mobile-logo" />
              </h1>
            </Link>
          </Box>
        </Grid>

        {/* Right - Buttons and Hamburger Menu */}
        <Grid item xs={6} md={6}>
          <Box
            sx={{
              display: { xs: 'none', md: 'flex' }, // Hide buttons on small screens
              justifyContent: 'flex-end',
              gap: '20px',
              marginRight: '30px',
            }}
          >
                     <div class="dropdown">
            <a className="loginbtn d-lg-block d-none dropdown-toggle" id="dropdownMenu2" data-bs-toggle="dropdown" target="_blank" href="#">
              Login  <img src="/img/arrow-down.svg" alt="mobile logo" />
            </a>
  <ul class="dropdown-menu" aria-labelledby="dropdownMenu2">
    <li><Link to='/guest' ><button class="dropdown-item" type="button"> <img src={guestimg} alt="mobile logo" className=""  width={24}/>Login as Guest</button></Link></li>
    <li> <Link to='/host'><button class="dropdown-item" type="button"><img src={hostimg} alt="mobile logo" className=""  width={24}/>Login as Host</button></Link></li>
  
  </ul>
</div>

            <Button
            data-bs-toggle="modal"
                    data-bs-target="#exampleModal"
              // onClick={handleApplybtn}
              sx={{
                width: '166px',
                height: '45px',
                fontSize: '15px',
                backgroundColor: 'white',
                color: 'black',
                borderRadius: '24px',
                padding: '12px 16px',
                fontWeight: 600,
                fontFamily: 'Manrope',
                textTransform: 'none',
                "&:hover": {
                  background: 'transparent',
                  color: 'white',
                  textAlign: 'center',
                },
              }}
              variant="contained"
            >
              Apply For Beta
            </Button>
          </Box>
          {/* Hamburger Icon for Small Screens */}
          <Box
            sx={{
              display: { xs: 'block', md: 'none' }, // Show only on small screens
              textAlign: 'right',
              marginRight: '16px',
            }}
          >
            <IconButton onClick={() => setIsDrawerOpen(true)} sx={{ color: 'white' }}>
              <MenuIcon />
            </IconButton>
          </Box>
        </Grid>
      </Grid>

      {/* Drawer for Small Screens */}
      <Drawer
        anchor="right"
        open={isDrawerOpen}
        onClose={() => setIsDrawerOpen(false)}
        PaperProps={{
          sx: {
            width: '250px',
            backgroundColor: '#333', // Adjust drawer background color
            color: 'white',
          },
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            padding: '16px',
            gap: '20px',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <Typography variant="h6">Menu</Typography>
            <IconButton onClick={() => setIsDrawerOpen(false)} sx={{ color: 'white' }}>
              <CloseIcon />
            </IconButton>
          </Box>

          <Button
            onClick={handleLogin}
            sx={{
              color: 'white',
              border: '1px solid white',
              borderRadius: '8px',
              textTransform: 'none',
            }}
            variant="outlined"
          >
            Login
          </Button>

          <Button
          data-bs-toggle="modal"
                    data-bs-target="#exampleModal"
         
            sx={{
              backgroundColor: 'white',
              color: 'black',
              borderRadius: '8px',
              textTransform: 'none',
            }}
            variant="contained"
          >
            Apply For Beta
          </Button>
          
        </Box>
      </Drawer>

    </>
  );
};

export default RentHeader;
