import React, { useEffect } from 'react';
import './Rentacademyanimation.css'; // Assuming your CSS is in a separate file named styles.css
import img1 from './assets/drag-drop_1.svg'
import img2 from './assets/umbrella_1.svg';
import img3 from './assets/voice_1.svg';
import img4 from './assets/rentacademy.svg';
import img5 from './assets/car-shield_1.svg';
import img6 from './assets/books_1.svg'
import img7 from './assets/headphones_1.svg';
const Rentacademyanimation = () => {
  
  useEffect(() => {
    const container3 = document.querySelector('.container3');
    const wrappers3 = document.querySelector('.wrappers3');

    const images = [
      img1, img2, img3, img4, img5,img6,img7
    ];

    // Generating the Card for using Array
    const elementsData = [
      "FleetEssential",
      "RiskManagement",
      "RevenueOptimization",
      "RentAcademy",
      "OperationsExcellence",
       "BestPractices",
      "CustomerExperience"
    
     
     
    ];

    Array.from({ length: 36 }).forEach((_, index) => {
      const elements = document.createElement('div');
      elements.classList.add('items8', `item-${index + 1}`);

      if (elementsData[index]) {
        const title = document.createElement('span');
        //title.innerHTML = elementsData[index];


        
        let camelCaseText = title.innerHTML; // Extract the current text
        camelCaseText = elementsData[index]; // Update the variable
		    let formattedText = camelCaseText.replace(/([A-Z])/g, ' $1').trim();
        title.innerHTML =  formattedText; 

         const img = document.createElement('img');
        img.src = images[index % images.length]; // Cycle through images
        img.classList.add('icon');



        if (elementsData[index] === "RentAcademy") {
          elements.classList.add('rentacademy-card'); // Add custom class here
        }


        elements.append(img);
        elements.append(title);
        elements.style.gridArea = elementsData[index];
        elements.setAttribute('data-name', elementsData[index]);

        wrappers3.append(elements);
      }
    });

    // Generating SVG Line for each Element
    const elementsDataName = [
     ["OperationsExcellence", "FleetEssential"],
      ["RentAcademy", "BestPractices"],
      ["RentAcademy", "RiskManagement"],
      ["RevenueOptimization", "FleetEssential"],
      ["RentAcademy", "CustomerExperience"],
      // ["RentAcademy", "CustomerExperience"],
    ];
  
    // Generating SVGs inside the .container3
    elementsDataName.forEach((_, index) => {
      const svgcontainer3 = document.createElementNS("http://www.w3.org/2000/svg", "svg");
      svgcontainer3.classList.add('svg-lines3', `svg-lines3-${index + 1}`);
      container3.appendChild(svgcontainer3);
    });

    // Function to create SVG paths
    const createSVGPath = (startX, startY, endX, endY) => {
      const svgNS = "http://www.w3.org/2000/svg";
      const path = document.createElementNS(svgNS, "path");
      const pathData = `
        M ${startX} ${startY}
        L ${startX} ${endY}
        L ${endX} ${endY}
        L ${endX} ${endY}
      `;
      path.setAttribute("d", pathData);
      path.setAttribute("stroke", "orange");
      path.setAttribute("stroke-width", "3");
      path.setAttribute("fill", "none");
      path.classList.add("animate-path5");

      return path;
    };

    const connectElements = () => {
      elementsDataName.forEach((name, index) => {
        const svgcontainer3 = document.querySelector(`.svg-lines3-${index + 1}`);
        const [startName, endName] = name;

        const item1 = document.querySelector(`[data-name='${startName}']`);
        const item2 = document.querySelector(`[data-name='${endName}']`);

        // Clear previous SVG content
        svgcontainer3.innerHTML = "";

        // Get positions and dimensions
        const rect1 = item1.getBoundingClientRect();
        const rect2 = item2.getBoundingClientRect();
        // Calculate start and end points
        const startX = rect1.left + rect1.width / 2;
        const startY = rect1.top + rect1.height / 2;
        const endX = rect2.left + rect2.width / 2;
        const endY = rect2.top + rect2.height / 2;

        // Append path to SVG container3
        svgcontainer3.appendChild(createSVGPath(startX, startY, endX, endY));
      });
    };

    const drawLines = () => {
      connectElements();
      const paths = document.querySelectorAll('.animate-path5');

      paths.forEach((path) => {
        const pathLength = path.getTotalLength();
        path.style.strokeDasharray = pathLength;
        path.style.strokeDashoffset = pathLength;
        path.style.setProperty('--path-length', `${pathLength}px`);
      });
    };

    const animatingLine = async () => {
      const path1 = document.querySelector('.svg-lines3-1 path');
      const path2 = document.querySelector('.svg-lines3-2 path');
      const path3 = document.querySelector('.svg-lines3-3 path');
      const path4 = document.querySelector('.svg-lines3-4 path');
      const path5 = document.querySelector('.svg-lines3-5 path');
      const path6 = document.querySelector('.svg-lines3-6 path');
      const RentAcademy = document.querySelector("[data-name='RentAcademy']");
      const FleetEssential = document.querySelector("[data-name='FleetEssential']");
      const RiskManagement = document.querySelector("[data-name='RiskManagement']");
      const RevenueOptimization = document.querySelector("[data-name='RevenueOptimization']");
      const CustomerSupport = document.querySelector("[data-name='CustomerSupport']");
      const CustomerExperience = document.querySelector("[data-name='CustomerExperience']");
      const BestPractices = document.querySelector("[data-name='BestPractices']");
      const OperationsExcellence = document.querySelector("[data-name='OperationsExcellence']");

      async function animateCards() {
        while (true) {
            // First Animation Sequence
            eachAnimation(path1, OperationsExcellence, FleetEssential, "add");
            await delay(2000);
            eachAnimation(path1, OperationsExcellence, FleetEssential, "remove");
           
            eachAnimation(path2, RentAcademy, BestPractices, "add");
            await delay(2000);
            eachAnimation(path2, RentAcademy, BestPractices, "remove");


            eachAnimation(path3, RentAcademy, RiskManagement, "add");
            await delay(2000);
            eachAnimation(path3, BestPractices, RiskManagement, "remove");


            
            eachAnimation(path4, RevenueOptimization, FleetEssential, "add");
            await delay(2000);
            eachAnimation(path4, RevenueOptimization, FleetEssential, "remove");


            eachAnimation(path5, RentAcademy, CustomerExperience, "add");
            await delay(2000);
            eachAnimation(path5, RentAcademy, CustomerExperience, "remove");

            
               
          
        }
    }
    function delay(ms) {
      return new Promise((resolve) => setTimeout(resolve, ms));
  }
  animateCards();
    };
    

    const eachAnimation = (path, card1, card2, action) => {

      const text1 = card1.querySelector('span');
      const text2 = card2.querySelector('span');


      if (action === "add") {
        path.classList.add("animate-now8");
        card1.classList.add("animate-now8");
        //card2.classList.add("animate-now8-delay");
        setTimeout(() => {
          card2.classList.add('animate-now8-delay');
      }, 1100);
        if (text1) text1.classList.add("show-text");
        if (text2) text2.classList.add("show-text");
      } else if (action === "remove") {
        path.classList.remove("animate-now8");
        card1.classList.remove("animate-now8");
        card2.classList.remove("animate-now8-delay");
      }
    };

    // Initial drawing and animation
    drawLines();
    animatingLine();

    // Event listeners
   // window.addEventListener("resize", connectElements);

    // Cleanup function
    return () => {
      window.removeEventListener("resize", connectElements);
      // Clean up any SVGs or elements created if necessary
    };
  }, []); // Empty dependency array to run only on mount

  return (
    <div className="container3">
    
      <div className="wrappers3"></div>
    </div>
  );
};

export default Rentacademyanimation;
