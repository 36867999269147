import React from "react";
import logo from "./logo.svg";
import "./App.css";
import Header from "./Component/Header/Header";
import Footer from "./Component/Footer/Footer";
import LandingPage from "./pages/LandingPage";
import { Route, Routes } from "react-router-dom";
import Home from "./pages/Home";
import Privacy from './pages/Privacy'
import Terms from "./pages/Terms";
import Betasignup from "./Component/BetaSignup/Betasignup";
import ThankYou from "./pages/ThankYou";
import BetaCongratulation from "./pages/BetaCongratulation";
import BetaThankyou from "./pages/BetaThankyou";
import { UserFormDataProvider } from "./context/FormDataContext";
import RentDirect2 from "./Component/RentDirect2/RentDirect2";
import Rentshield2 from "./Component/RentShield2/Rentshield2";
import RentId2 from "./Component/Rentid2/RentId2";
import RentAcademy2 from "./Component/RentAcademy2/RentAcademy2";
import RentSuccess2 from "./Component/RentSuccess2/RentSuccess2";
import Guest from "./Component/Guest/Guest";
import Host from "./Component/Host/Host";
import AnimationCards from "./Component/SupportTeam/Animationcards";

function App() {
  return (
    <div className="App">
      <Routes>
      <Route path="/rentdirect" element={<RentDirect2 />} />
      <Route path="/rentshield" element={<Rentshield2 />} />
      <Route path="/rentid" element={<RentId2 />} />
      <Route path="/rentacademy" element={<RentAcademy2 />} />
      <Route path="/rentsuccess" element={<RentSuccess2 />} />
      <Route path="/guest" element={<Guest />} />
      <Route path="/Host" element={<Host />} />
    
        {/* <Route path='/' element={<Home/>} /> */}
        <Route path="/" element={
          <UserFormDataProvider>
            <LandingPage />
          </UserFormDataProvider>} />
        <Route path="privacy" element={<Privacy />} />
        <Route path="terms" element={<Terms />} />
        <Route
          path="beta-signup"
          element={
            <UserFormDataProvider>
              <Betasignup />
            </UserFormDataProvider>
          }
        />
        <Route
          path="thankyou"
          element={
            <UserFormDataProvider>
              <ThankYou />
            </UserFormDataProvider>
          }
        />
        <Route path="congatulation" element={<BetaCongratulation />} />
        <Route path="betathankyou" element={  <UserFormDataProvider>
          <BetaThankyou />
          </UserFormDataProvider>} />
      </Routes>
    </div>
  );
}

export default App;
