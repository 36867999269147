import React, { useContext } from 'react';
import './betathankyou.css';
import { useLocation } from 'react-router-dom';
import { UserContext } from "../../context/FormDataContext";
// import thankyou from "../../assets/images/green-anim.gif";
import { createGlobalStyle } from 'styled-components';
import AnimationComponent from "../../Component/BetaSignup/loader_animation";
const BetaThankyou = () => {
  const location = useLocation();

  console.log("BetaThankyou", location.state);
  const { userData } = useContext(UserContext);
  console.log("Beta thank you",userData)

  const GlobalStyle = createGlobalStyle`
  .modal-open {
    overflow: scroll !important;
    padding-right: 0 !important;
  }
`;
const copyToClipboard = () => {
  const staticLink = "https://rentid.io/"; // Static link to copy
  navigator.clipboard.writeText(staticLink)
};
  return (
    <>
      <GlobalStyle/>
   
    <div className="main-header-sec" >
        
      <main className="main betathankyou">
        <div className="container-fluid">
        <header className="tankyou-header">
                    <div className="row beta-header align-items-center">
                      
                      <div className="col-md-4 d-flex">
                        <a href="/">
                          <img
                            src="/img/beta-signup/mobile-logo.png"
                            className="img-fluid"
                            alt="feature img"
                            style={{ maxHeight: "50px" }}
                          />
                        </a>
                      </div>

                      <div className="col-md-8 text-end">
                        <a href="/" className="btn return-hone">
                          Return Home
                        </a>
                      </div>
                    </div>
                  </header>
          <div className="row">
            <div className="col-12 col-xs-12 col-md-8 col-lg-8 p-0 mx-auto">
              <div className="row center-row align-items-center">
                {/* <div className="col-12 p-md-0 main-logo">
                  <a href="/">
                    <img
                      src="/img/beta-signup/mobile-logo.png"
                      className="img-fluid"
                      alt="feature img"
                    />
                  </a>
                </div> */}
                <div className="col-12 text-md-center mx-auto">
                  <div className="row">
                    <div className="col-12 p-md-0">
                      <div className="img-section-img text-center">
                        {/* <img
                          src="/img/beta-signup/Featured.png"
                          className="img-fluid"
                          alt="feature img"
                        /> */}
                        <AnimationComponent/>
                      </div>
                      <div className="img-section-logo text-center pt-0 py-4">
                        <img
                          src="/img/rentid-logo.svg"
                          className="img-fluid"
                          alt="feature img"
                        />
                       
                      </div>
                      <p className="thanks-congrats-text text-center pt-2">
                        <span>Congratulations  {userData.firstName}</span>
                      </p>
                      <div className="row details-section text-center">
                        <div className="col-12 p-0 px-md-3">
                          {/* <p className="thanks-user-name">
                            {userData.firstName} {userData.lastName} 
                          </p> */}
                          <p className="thanks-desc-notes mb-2">
                          You’ve successfully applied for our beta program, and we’re thrilled to welcome you to the Rentid family! Keep an eye on your inbox for a confirmation email, as we’ll be reaching out to you soon.
                          </p>
                        
                          <div className='d-flex gap-3 congrulayion-btn align-items-center justify-content-center'>
                          <button
                              type="button" 
                              onClick={copyToClipboard}
                              className="btn btn-outline-dark returnbtn sharebtn radius-5"                          
                            >
                              <img
                          src="/img/beta-signup/share.svg"
                          className="img-fluid px-1"
                          alt="feature img"
                        />  Share rentid with a friend
                            </button>
                            <a href="/">
                          <button
                            type="button"
                            className="btn btn-radius thanbtn"                           
                          >
                            Done!
                          </button>
                          </a>
                        
                          </div>
                       
                        </div>
                        {/* <div className="col-12 p-0 px-md-3">
                      
                        </div> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="thankyou-footer">
                        <div className='row thankyou-footer'>
                          <div className='col-md-4 col-lg-4'>
                            <ul className='d-flex thank-footer gap-4'>
                            <li>
                          <span>
                            <img
                              src="/img/beta-signup/elements.png"
                              className="img-fluid"
                              alt="copy right"
                            />
                            2024 Rentid Inc.
                          </span>
                          </li>

                          <li className=''>Terms</li>
                          <li>Privacy</li>
                            </ul>
                       
                          </div>

                          <div className='col-md-8 col-lg-8 text-end thankyou-sec pl-4'>
                            <ul class="d-flex thank-footer-right gap-4">
                            <li>
                          English (US)
                          </li>

                          <li>Rp IDR</li>
                          <li>Support</li>
                            </ul>
                        
                          </div>

                        </div>
                       </div>
        </div>
        
      </main>
   
     
    </div>
     </>
  );
}

export default BetaThankyou;
