import React, { useState, useEffect } from 'react';
import { Field, ErrorMessage, useFormikContext } from 'formik';

const InsuranceProvider = ({ insuranceData, setInsuranceData, handleChangeInsure }) => {
  const { setFieldValue, setFieldTouched, errors, touched, values } = useFormikContext();
  const [selectedInsuranceProviders, setSelectedInsuranceProviders] = useState(values.selected_insurance_providers || []);
  const [useInsuranceServices, setUseInsuranceServices] = useState(false);
  const [isChecked, setIsChecked] = useState(values.insurance_provider_option_detail === 'other');

  const insuranceOptions = [
    { value: '1', src: '/img/beta-signup/turo.svg', alt: 'brand1' },
    { value: '2', src: '/img/beta-signup/addify.svg', alt: 'brand2' },
    { value: '3', src: '/img/beta-signup/sonoran.svg', alt: 'brand3' },
    { value: '4', src: '/img/beta-signup/tint.svg', alt: 'brand4' },
    { value: '5', src: '/img/beta-signup/liberty.svg', alt: 'brand5' },
    { value: '6', src: '/img/beta-signup/american.svg', alt: 'brand6' }
  ];

  const others = [7];

  const handleCheckboxChange = (event) => {
    const { value } = event.target;
    const updatedProviders = selectedInsuranceProviders.includes(value)
      ? selectedInsuranceProviders.filter((v) => v !== value)
      : [...selectedInsuranceProviders, value];

    setSelectedInsuranceProviders(updatedProviders);
    setFieldValue('selected_insurance_providers', updatedProviders);
    handleChangeInsure(event); // Call the original handleChangeInsure function
  };

  useEffect(() => {
    if (!values.insurance_provider) {
      setFieldValue('insurance_provider', '0');
    }
  }, [values, setFieldValue]);
// console.log("bfgsdfg", values);
  useEffect(() => {
    setFieldValue('selected_insurance_providers', selectedInsuranceProviders);
  }, [selectedInsuranceProviders, setFieldValue]);

  const toggleInsuranceService = () => {
    const newValue = !useInsuranceServices ? '1' : '0';
    setUseInsuranceServices(!useInsuranceServices);
    setFieldValue('insurance_provider', newValue);

    if (!useInsuranceServices) {
      setSelectedInsuranceProviders([]);
      setFieldValue('selected_insurance_providers', []);
    }
  };

  const handleCheckboxOtherChange = (event) => {
    setIsChecked(event.target.checked);
    // setFieldValue('insurance_provider_option_detail', event.target.checked ? '' : '');
    setFieldValue('selected_insurance_providers', others);
  };

  const labelClassName = `form-check-label form-check-field w-100 ${isChecked ? 'checked-class' : ''}`;

  return (
    <div className="h-100">
      <div className="row h-100 align-items-center insurance-provid">
        <div className="col-12 col-xs-12 col-md-12 px-0">
          <div className="general-info-form py-5">
            <div className="row">
              <div className="col-12 p-0">
                <p className="llc-text pb-0 mb-2">
                  Who is your insurance provider?
                </p>
                <p className="llc-desc ">
                  Select all that apply
                </p>
              </div>
            </div>
            <div className="row my-2">
              {/* <ErrorMessage name="insurance_provider" component="div" className="invalid-feedback" /> */}
              <div className={`col-12 togglebtn ${useInsuranceServices ? 'enabled' : ''}`}>
                I’m not using Insurance services
                <label className="switch">
                  <input
                    type="checkbox"
                    value={useInsuranceServices ? '1' : '0'}
                    name="insurance_provider"
                    checked={useInsuranceServices}
                    onChange={toggleInsuranceService}
                  />
                  <span className="slider round"></span>
                </label>
              </div>
            </div>
            <div className={`row providerimg ${useInsuranceServices ? 'disabled-glass' : ''}`}>
            {touched.selected_insurance_providers && errors.selected_insurance_providers ? (
                <div className="invalid-feedback">{errors.selected_insurance_providers}</div>
              ) : null}
              {insuranceOptions.map((option, index) => (
                <div key={option.value} className={`col-12 col-xs-12 col-md-6 ${index % 2 !== 0 ? 'px-md-2' : ''} mt-3 insurance-form ps-0`}>
                  <div className="form-check ps-0">
                    <Field
                      type="checkbox"
                      id={`providers${option.value}`}
                      name="selected_insurance_providers"
                      value={option.value}
                      className="form-check-input d-none"
                      onChange={handleCheckboxChange}
                      checked={selectedInsuranceProviders.includes(option.value)}
                      onBlur={() => setFieldTouched('selected_insurance_providers', true)}
                      disabled={useInsuranceServices}
                    />
                    <label
                      className={`form-check-field list-group-item w-100 ${selectedInsuranceProviders.includes(option.value) ? 'selected' : ''}`}
                      htmlFor={`providers${option.value}`}
                    >
                      <div className="row align-items-center justify-content-center">
                        <div className="col-12 text-center">
                          <img
                            src={option.src}
                            className="img-fluid brand-img"
                            alt={option.alt}
                          />
                        </div>
                      </div>
                    </label>
                  </div>
                </div>
              ))}

              {/* <div className="col-12 col-xs-12 col-md-6 px-md-2 mt-3">
                <div className="form-check ps-0 invisible">
                  {insuranceData}
                </div>
              </div> */}
            </div>

            <div className={`row ${useInsuranceServices ? 'disabled-glass' : ''}`}>
              <div className="col-12 col-xs-12 col-md-12 mt-3 pe-0 ps-0">
                <div className="form-check ps-0">
                  <label className={labelClassName} htmlFor="Other">
                    <div className="row">
                      <div className="col-12 p-0">
                        <div className="row">
                          <div className="col-10 ps-0 ">
                            <img src="/img/beta-signup/security.svg" className="img-fluid" alt="other" />
                          </div>
                          <div className="col-2 text-end d-none">
                            <Field
                              type="checkbox"
                              id="Other"
                              name="insurance_provider_option_detail"
                              value="other"
                              className="form-check-input checkbox-right"
                              onChange={handleCheckboxOtherChange}
                              checked={isChecked}
                            />
                          </div>
                        </div>
                        <div className="row mt-2">
                          <div className="col-12 ps-0">
                            <p className="llc-heading mb-0">Other</p>
                          </div>
                        </div>
                      </div>
                      {isChecked && (
                        <div className="col-12 mt-2 p-0">
                          <Field
                            type="text"
                            className="form-control"
                            id="whichOne"
                            name="insurance_provider_option_detail"
                            placeholder="Which one?*"
                          />
                          <ErrorMessage name="insurance_provider_option_detail" component="div" className="invalid-feedback" />
                        </div>
                      )}
                    </div>
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InsuranceProvider;
