import React, { useState } from "react";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import hostimg from "./assets/hostimg.svg";
import logo from "./assets/logo.svg";
import close from "./assets/Close X.svg";
import "./hoststyle.css";
import { Link } from "react-router-dom";
const Host = () => {
  const [showPassword, setShowPassword] = useState(false);

  // Validation Schema
  const validationSchema = Yup.object({
    emailOrPhone: Yup.string()
      .required("Email or Phone number is required")
      .matches(
        /^(\+?[0-9]{7,15}|[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,})$/,
        "Enter a valid email or phone number"
      ),
    password: Yup.string()
      .required("Password is required")
      .min(6, "Password must be at least 6 characters"),
  });

  // Initial Values
  const initialValues = {
    emailOrPhone: "",
    password: "",
  };

  // Submit Handler
  const onSubmit = (values) => {
    console.log("Form Data", values);
    // Handle the login logic here
  };

  return (
    <div className="rent-idlogin">
      <div className="container">
        <nav className="navbar">
          <a href="/" className="navbar-brand">
            <img
              src={logo}
              alt="Logo"
              className="d-inline-block align-text-top"
              style={{ height: "40px" }}
            />
          </a>
        </nav>
        <div className="row align-items-center login-host">
          {/* Image Section */}
          <div className="col-md-6 host-banner text-center">
            <img src={hostimg} alt="Host" className="img-fluid" />
          </div>

          {/* Login Form Section */}
          <div className="col-md-6 ">
            <div className="login-content-host">
              <h1>
              <Link to='/'><img src={close} alt="Host" className="img-fluid pr-2"  /></Link>  Host Account{" "}
                 <strong>Login</strong> 
              </h1>

              {/* Formik Form */}
              <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={onSubmit}
              >
                {({ errors, touched }) => (
                  <Form>
                    {/* Email/Phone Field */}
                    <div className="mb-4">
                      <label htmlFor="emailOrPhone" className="form-label">
                        Email or Phone number
                      </label>
                      <Field
                        type="text"
                        id="emailOrPhone"
                        name="emailOrPhone"
                        className={`form-control ${
                          errors.emailOrPhone && touched.emailOrPhone
                            ? "is-invalid"
                            : ""
                        }`}
                        placeholder="Enter email or phone number"
                      />
                      <ErrorMessage
                        name="emailOrPhone"
                        component="div"
                        className="invalid-feedback"
                      />
                    </div>

                    {/* Password Field */}
                    <div className="mb-4">
                      <label htmlFor="password" className="form-label">
                        Password
                      </label>
                      <div className="input-group">
                        <Field
                          type={showPassword ? "text" : "password"}
                          id="password"
                          name="password"
                          className={`form-control ${
                            errors.password && touched.password ? "is-invalid" : ""
                          }`}
                          placeholder="Enter password"
                        />
                        { <button
                          type="button"
                          className="btn btn-outline-secondary password-toggle"
                          onClick={() => setShowPassword(!showPassword)}
                        > 
                          <img
                              src='/img/view-off.svg'
                              alt="Logo"
                              className="d-inline-block align-text-top"
                              style={{ height: "40px" }}
                            />
                        </button> }
                      </div>
                      <ErrorMessage
                        name="password"
                        component="div"
                        className="invalid-feedback"
                      />
                    </div>


                    {/* Forgot Password Link */}
                    <div className="mb-4 text-end">
                      <a href="#" className="text-decoration-none">
                        Forgot Password?
                      </a>
                    </div>

                    {/* Login Button */}
                    <button type="submit" className="btn btn-secondary w-100">
                      Login
                    </button>

                    <div className="mb-4 mt-4 text-center">
                      <a href="#" className="signup-txt">
                        Don’t have an account? Sign up for rentid.
                      </a>
                    </div>
                    <div className="mb-0 text-center">
                      <p>
                        By signing up, you agree to the{" "}
                        <a href="#">Terms of Service</a> &{" "}
                        <a href="#">Privacy Policy</a>, including our{" "}
                        <a href="#">Cooking Policy</a>. Rentid 2024
                      </p>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Host;
