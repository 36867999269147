import React from 'react';
import { Box, Button, Container, Grid, Typography } from '@mui/material';
import logo from '../../assets/images/logo_new.png';
import arrow_down from "../../assets/images/arrow-down-01.png"
import academy from "./assets/rentacad.svg";
import rend_id from "../../assets/images/rend_id.png";
import rend_id2 from "../../assets/images/yellowglow.png";
import { Link } from 'react-router-dom';
import Rentacademyanimation from './Rentacademyanimation';
import RentHeader from '../RentHeader/RentHeader';
import BetaForm from '../BetaForm';
const RentAcademy2 = () => {

  return (
    <>
         <Box
        sx={{
          background: 'linear-gradient(180deg, #082C72 0%, #001132 55%, #000000 100%)',
          height: '',
          width: '100%',
        }}
      >
        <Container>
         <RentHeader/>


{/* Banner section start from here */}

        <Box sx={{marginTop:'10%'}}>
        <Grid container spacing={5}>
          <Grid item md={5}>
            <Box>
            <img src={academy}></img>
            <Typography sx={{fontSize:'17px',
            width:'390px',
            paddingTop:'5%',
             fontFamily:'Manrope', 
             fontWeight:700,
              color:'#FFFFFF'}}>RENTAL BEST PRACTICES FOR GROWTH</Typography>
              <Typography sx={{
                width:'346px',
                fontSize:'42px',
             paddingTop:'5%',
             fontFamily:'Manrope', 
             fontWeight:600,
              color:'#FFFFFF',
              lineHeight:'52px'
              }}>
             Learn on your time,<span className="uper-span" style={{background:'linear-gradient(90.02deg,#b9fcf4 3.13%,#e9c9fc 66.57%)', color:"black",  padding:'px',  fontWeight:800,borderRadius:'4px' , lineHeight:'64px', padding:"0 4px"}}>not your</span> <span style={{background:'linear-gradient(90.02deg,#b9fcf4 3.13%,#e9c9fc 66.57%)', color:"black",  padding:'px',  fontWeight:800,borderRadius:'4px' , lineHeight:'64px', padding:"0 4px"}}>dime.</span>
              </Typography>
              <Typography sx={{
                width:'488px',
                  fontSize:'20px',
             paddingTop:'5%',
             fontFamily:'Manrope', 
             fontWeight:300,
              color:'#FFFFFF',
              lineHeight:'25px'
              }}>
             Leverage our platform to educate yourself, inform your guests, and train your staff for healthier operations.
              </Typography>
            </Box>
        </Grid>

        <Grid item md={7}>
            {/* <img src={rend_id2} alt=''/> */}
          <Rentacademyanimation />
        </Grid>
       </Grid>
        </Box>
        
        </Container>
      </Box>
      <BetaForm/>
    </>
  )
}

export default RentAcademy2
