import React, { useState, useContext } from 'react'
import { Form, Modal,Button } from 'react-bootstrap';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import qs from 'qs';
import axios from 'axios';
import { UserContext } from '../../context/FormDataContext.js';
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input';
import { API_ENDPOINT } from '../../Constant/Constant';
import './AnimationSection.css';

import { Navigate, useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
function AnimationSection({ activeKey, setActiveKey, isVisible, setIsVisible, handleCloseButtonClick, tabTitle, handleTabSelect }) {

    const [hoveredTab, setHoveredTab] = useState(null);
    const navigate = useNavigate()

    const handleClick = (event) => {
        setActiveKey(event.target.id);
        setIsVisible(true);
    };

    const handleFooterMenuClick = (key) => {
        setActiveKey(key);
        setIsVisible(true);
        // alert(key)
    };

    
    const handleRedirect = ()=> {
        window.location.href = '/rentdirect'; 
      }

      const handleShield = ()=> {
        window.location.href = '/rentshield'; 
      }

      const handleId = ()=> {
        window.location.href = '/rentid'; 
      }

      const handleIacademy = ()=> {
        window.location.href = '/rentacademy'; 
      }

      const handlesuccess = ()=> {
        window.location.href = '/rentsuccess'; 
      }


      const [show,setShow]=useState(false);
     
      const { updateUserFormData } = useContext(UserContext);
      const [isExpanded, setIsExpanded] = useState(false);
      const text = `By submitting this form, you consent to Rentid contacting you via SMS, phone, and email for information about our services, promotional offers, and updates. You understand and agree that Rentid may use automated technology including autodialing, AI generated messaging, and AI generated voice to contact you, and that consent is not a condition of purchase. Message frequency may vary, and message/data rates may apply. You can opt out at any time by following the instructions in our communications or by contacting us at contact@rentid.io.`;
  
      const shortText = text.slice(0, 120) + '...'; // First 160 characters + ellipsis
      const readMoreText = isExpanded ? text : shortText;
      const [value, setValue] = useState();
  
  const validationSchema = Yup.object().shape({
      firstName: Yup.string().required('First Name is required'),
      lastName: Yup.string().required('Last Name is required'),
      // companyName: Yup.string().required('Company Name is required'),
      phone: Yup.string()
      .required('Phone number is required'),
      // .min(10, 'Phone number must be at least 10 digits'),
      email: Yup.string().email('Email is invalid').required('Email is required'),
      state: Yup.string().required('State is required'),
      fleet: Yup.string().required('Fleet size is required')
  });
  
      const { register, handleSubmit, formState: { errors }, reset, control } = useForm({
          resolver: yupResolver(validationSchema)
      });
  
      const onSubmit = async (data) => {        
          try {
              // Prepare the form data object
              const formData = {
                  'waitlist[first_name]': data.firstName || 'First Name',
                  'waitlist[last_name]': data.lastName || 'Last Name',
                  'waitlist[company_name]': data.companyName || 'Company Name',
                  'waitlist[phone_number]': data.phone || 'Phone Number',
                  'waitlist[email_address]': data.email || 'Email Address',
                  'waitlist[state]': data.state || 'State',
                  'waitlist[fleet_size]': data.fleet || 'Fleet Size',
              };
      
              // Convert formData object to URL-encoded string
              const urlEncodedData = qs.stringify(formData);
      
              // Configure the request
              let config = {
                  method: 'post',
                  // url: 'https://api.getrentid.io/api/waitlist/submit-waitlist',
                  url: `${API_ENDPOINT}/waitlist/submit-waitlist`,
                  headers: { 
                      'Content-Type': 'application/x-www-form-urlencoded',
                  },
                  data: urlEncodedData
              };
      
              // Make the request
              axios.request(config)
                  .then((response) => {
                      console.log(JSON.stringify(response.data));
                      updateUserFormData(data.firstName, data.lastName,data.companyName, data.phone,data.email, data.state,data.fleet);
                      navigate("/beta-signup");
                      // window.location.href = '/thankyou';
                  })
                  .catch((error) => {
                      console.error('Error submitting form:', error);
                  });
      
              // Reset the form
              handleClose();
              reset();
          } catch (error) {
              console.error('Error submitting form:', error);
          }
      };
  
  
      // show={show} onHide={handleClose}
      const handleModal=()=>{
          setShow(!show)
      }
      const handleClose=()=>{
          setShow(false)
      }


    return (
        <>
            <section id="third" class="animation_sec">


                <div className="container">
                    <div className="row">
                        <div className="col-12 text-lg-center text-start px-0">
                            <img className="logo11" src="/img/Rentid-white.svg" alt="Rentid Logo" width={117} />
                            <h2>
                                Seamless, effortless, and commissionless rental platform.
                            </h2>
                        </div>
                    </div>
                   
                     <div className="mobielhovernone">
                        <div className="hover-card rent-direct">
                           <div class="rent-overlay"></div>
                            <div className="logo-container" onClick={handleRedirect}>
                                <img className="logo" src="/img/animation/rentidirect.svg" alt="Animation Image" />
                        <p ><span style={{}}>rent</span>direct</p>
                            </div>
                        </div>
                     
                        <div className="hover-card rent-id">
						      <div class="rent-overlay"></div>
                              <div className="logo-container" onClick={handleId}>
                                <img className="logo" src="/img/animation/rent-id-icon.svg" alt="Animation Image" />
                          <p><span>rent</span>ID</p>
                            </div>
                        </div>
                       
                        <div className="hover-card rent-shield">
						      <div class="rent-overlay"></div>
                            <div className="logo-container" onClick={handleShield}>
                                <img className="logo" src="/img/animation/rent-shield.svg" alt="Animation Image" />
                          <p><span>rent</span>shield</p>
                            </div>
                        </div>
                        <div className="hover-card rent-success">
						     <div class="rent-overlay"></div>
                            <div className="logo-container" onClick={handlesuccess}>
                                <img className="logo" src="/img/animation/rent-success.svg" alt="Animation Image" />
                          <p><span>rent</span>success</p>
                            </div>
                        </div>
                        <div className="hover-card rent-accademy">
						     <div class="rent-overlay"></div>
                            <div className="logo-container" onClick={handleIacademy}>
                                <img className="logo" src="/img/animation/rent-accademy.svg" alt="Animation Image" />
                          <p><span>rent</span>academy</p>
                            </div>
                        </div>
                    </div> 
            

                    {/* <div className="row d-lg-none d-block pt-4">
                        <div className="col-12 px-3">
                            <img src="/img/rentdirect-animation.svg" id='RentDirect' alt="Rentdirect Animation" className="w-100" onClick={handleClick} />
                        </div>
                        <div className="col-12">
                            <div className="row">
                                <div className="col-6 pb-3 subicons">
                                    <img src="/img/rentacademy-animation.svg" id='RentAcademy' alt="Rentacademy Animation" className="w-100" onClick={handleClick} />
                                </div>
                                <div className="col-6 pb-3 subicons">
                                    <img src="/img/rentsuccess-animation.svg" id='RentSuccess' alt="Rentsuccess Animation" className="w-100" onClick={handleClick} />
                                </div>
                                <div className="col-6 pb-3 subicons">
                                    <img src="/img/rentid-animation.svg" id='Rentid' alt="Rentid Animation" className="w-100" onClick={handleClick} />
                                </div>
                                <div className="col-6 pb-3 subicons">
                                    <img src="/img/rentshield-animation.svg" id='rentshield' alt="Rentshield Animation" className="w-100" onClick={handleClick} />
                                </div>
                            </div>
                        </div>

                    </div> */}

                

              <div class="col-md-12 text-center rental-plateform">
               <p>Our suite of connected products and tools converge to bring you the most advanced all-in-one rental platform.</p>
              </div>

              <div className="col-md-12 px-1 mb-5 d text-center mt-5">
                                    <button type="button" id="join_waits" className="btn btn-dark" onClick={handleModal}>Join The Waitlist</button>
                                </div>
                                <Modal id="waitlist" className='joinlist' show={show} onHide={handleClose} centered>
                    <Modal.Header closeButton>
                        <Modal.Title>Join The Waitlist</Modal.Title>
                    </Modal.Header>
                    <Form onSubmit={handleSubmit(onSubmit)}>
                        <Modal.Body>
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="row">
                                        <div className="col-md-6">
                                            <Form.Group className="mb-3">
                                                <Form.Label htmlFor="first_name">First Name<span class="required">*</span></Form.Label>
                                                <Form.Control type="text" id="first_name" placeholder='Enter first name' name="waitlist[first_name]" {...register('firstName')}
                                                     />
                                                <Form.Control.Feedback type="invalid">
                                                    {errors.firstName?.message}
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                        </div>
                                        <div className="col-md-6">
                                            <Form.Group className="mb-3">
                                                <Form.Label htmlFor="last_name">Last Name<span class="required">*</span></Form.Label>
                                                <Form.Control type="text" id="last_name" placeholder='Enter last name' name="lastName" {...register('lastName')}
                                                    />
                                                <Form.Control.Feedback type="invalid">
                                                    {errors.lastName?.message}
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                        </div>
                                        <div className="col-md-12">
                                            <Form.Group className="mb-3">
                                                <Form.Label htmlFor="company_name">Company name (Optional)</Form.Label>
                                                <Form.Control type="text" id="company_name" placeholder='Enter company name' name="companyName" {...register('companyName')}
                                                  />
                                                    isInvalid={!!errors.companyName} 
                                                <Form.Control.Feedback type="invalid">
                                                    {errors.companyName?.message}
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                        </div>
                                        <div className="col-md-6">
                                       <Form.Group className="mb-3">
                                    <Form.Label htmlFor="phone_number">
                                        Phone number<span className="required">*</span>
                                    </Form.Label>
                                    <Controller
                                        name="phone"
                                        control={control}
                                        rules={{
                                            required: "Phone number is required",
                                            validate: (value) => value && value.length >= 10 || "Phone number must be valid",
                                        }}
                                        render={({ field }) => (
                                            <PhoneInput
                                                {...field}
                                                id="phone_number"
                                                limitMaxLength="10"
                                                defaultCountry="US"
                                                placeholder="(000) 000-000"
                                                onChange={(value) => field.onChange(value)} // Ensure React Hook Form value updates
                                                value={field.value || ''}
                                            />
                                        )}
                                    />
                                    {errors.phone && (
                                        <Form.Control.Feedback type="invalid" className="d-block">
                                            {errors.phone.message}
                                        </Form.Control.Feedback>
                                    )}
                                </Form.Group>

                                        </div>
                                        <div className="col-md-6">
                                            <Form.Group className="mb-3">
                                                <Form.Label htmlFor="email_address">Email address<span class="required">*</span></Form.Label>
                                                <Form.Control type="email" id="email_address" placeholder='Enter your email' name="email" {...register('email')}
                                                    isInvalid={!!errors.email} />
                                                <Form.Control.Feedback type="invalid">
                                                    {errors.email?.message}
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                        </div>
                                        <div className="col-md-6">
                                            <Form.Group className="mb-3">
                                                <Form.Label htmlFor="state">State<span class="required">*</span></Form.Label>
                                                <Form.Select id="state" className="selectpicker" name="state" {...register('state')} isInvalid={!!errors.state}>
                                                    <option value="">Select</option>
                                                    <option value='Alabama'>Alabama</option>
                                                    <option value='Alaska'>Alaska</option>
                                                    <option value='Arizona'>Arizona</option>
                                                    <option value='Arkansas'>Arkansas</option>
                                                    <option value='California'>California</option>
                                                    <option value='Colorado'>Colorado</option>
                                                    <option value='Connecticut'>Connecticut</option>
                                                    <option value='Delaware'>Delaware</option>
                                                    <option value='Florida'>Florida</option>
                                                    <option value='Georgia'>Georgia</option>
                                                    <option value='Hawaii'>Hawaii</option>
                                                    <option value='Idaho'>Idaho</option>
                                                    <option value='Illinois'>Illinois</option>
                                                    <option value='Indiana'>Indiana</option>
                                                    <option value='Iowa'>Iowa</option>
                                                    <option value='Kansas'>Kansas</option>
                                                    <option value='Kentucky'>Kentucky</option>
                                                    <option value='Louisiana'>Louisiana</option>
                                                    <option value='Maine'>Maine</option>
                                                    <option value='Maryland'>Maryland</option>
                                                    <option value='Massachusetts'>Massachusetts</option>
                                                    <option value='Michigan'>Michigan</option>
                                                    <option value='Minnesota'>Minnesota</option>
                                                    <option value='Mississippi'>Mississippi</option>
                                                    <option value='Missouri'>Missouri</option>
                                                    <option value='Montana'>Montana</option>
                                                    <option value='Nebraska'>Nebraska</option>
                                                    <option value='Nevada'>Nevada</option>
                                                    <option value='New Hampshire'>New Hampshire</option>
                                                    <option value='New Jersey'>New Jersey</option>
                                                    <option value='New Mexico'>New Mexico</option>
                                                    <option value='New York'>New York</option>
                                                    <option value='North Carolina'>North Carolina</option>
                                                    <option value='North Dakota'>North Dakota</option>
                                                    <option value='Ohio'>Ohio</option>
                                                    <option value='Oklahoma'>Oklahoma</option>
                                                    <option value='Oregon'>Oregon</option>
                                                    <option value='Pennsylvania'>Pennsylvania</option>
                                                    <option value='Rhode Island'>Rhode Island</option>
                                                    <option value='South Carolina'>South Carolina</option>
                                                    <option value='South Dakota'>South Dakota</option>
                                                    <option value='Tennessee'>Tennessee</option>
                                                    <option value='Texas'>Texas</option>
                                                    <option value='Utah'>Utah</option>
                                                    <option value='Vermont'>Vermont</option>
                                                    <option value='Virginia'>Virginia</option>
                                                    <option value='Washington'>Washington</option>
                                                    <option value='West Virginia'>West Virginia</option>
                                                    <option value='Wisconsin'>Wisconsin</option>
                                                    <option value='Wyoming'>Wyoming</option>
                                                   
                                                </Form.Select>
                                                <Form.Control.Feedback type="invalid">
                                                    {errors.state?.message}
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                        </div>
                                        <div className="col-md-6">
                                            <Form.Group className="mb-3">
                                                <Form.Label htmlFor="fleet_size">Fleet size</Form.Label>
                                                <Form.Select id="fleet_size" className="selectpicker" name="fleet" {...register('fleet')} isInvalid={!!errors.fleet}>
                                                    <option value="">Select</option>
                                                    <option value="1">1</option>
                                                    <option value="2 to 5">2 to 5</option>
                                                    <option value="5 to 10">5 to 10</option>
                                                    <option value="Greater than 10">Greater than 10</option>
                                                </Form.Select>
                                                <Form.Control.Feedback type="invalid">
                                                    {errors.fleet?.message}
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                        </div>
                                        <p className='mb-0 disclaimer'>{readMoreText} <span onClick={() => setIsExpanded(!isExpanded)}>
                                            {isExpanded ? 'Read Less' : 'Read More'}
                                        </span></p>
                                        
                                        <p className="mt-3 mb-0 d-flex align-items-start">
                                            <input type="checkbox" id="privacy-checkbox" className="me-2"  required/>
                                            <label htmlFor="privacy-checkbox" className="me-2">
                                            I consent to receiving communications from Rentid via email, phone call, and SMS, including with the use of AI and automated dialing technologies, and I agree to Rentid’s <a href="terms" target="_blank" className="text-white">Terms of Service</a> and <a href="privacy" target="_blank" className="text-white">Privacy Policy</a>.
                                            </label>
                                           
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button type="submit" className='joinbtn' variant="primary">
                            Join The Waitlist
                            </Button>
                        </Modal.Footer>
                    </Form>
                </Modal>
{/* 
                <div id="thirdAnimation" className={`subparts ${isVisible ? 'full-screen' : 'd-none'}`}>
                    <div className="tab-control">
                        <button type="button" className="btn close d-lg-none d-block" onClick={handleCloseButtonClick}>
                            <img src="/img/left-arrow.svg" alt="close btn" className="w-100" />
                        </button>
                        <div className="backicons d-flex justify-content-between">
                            <button type="button" className="btn backbtn d-lg-block d-none" onClick={handleCloseButtonClick}>
                                <img src="/img/backbtn.svg" alt="close btn" className="w-100" />
                            </button>
                            <button type="button" className="btn close d-lg-block d-none" onClick={handleCloseButtonClick}>
                                <img src="/img/closebtn.png" alt="close btn" className="w-100" />
                            </button>
                        </div>
                    </div>

                    <div className="container">
                        <div className="row align-items-center">

                            <div className="col-md-12">
                                <Tabs
                                    activeKey={activeKey}
                                    onSelect={handleTabSelect}
                                    transition={false}
                                    id="centered-tab-example"
                                    className="d-flex justify-content-center mb-3"
                                >


                                    <Tab
                                        eventKey="home"
                                        title={
                                            <div className="tab-title-container">
                                                <img src={activeKey === 'home' ? '/img/1sticon-active.png' : '/img/1sticon.png'} alt="RentDirect Icon" />
                                                <span className="tab-tooltip"><span>Home</span></span>
                                            </div>
                                        }
                                    >
                                        <CompOne />
                                    </Tab>

                                    <Tab
                                        eventKey="RentDirect"
                                        title={
                                            <div className="tab-title-container">
                                                <img
                                                    src={hoveredTab === 'RentDirect' || activeKey === 'RentDirect' ? '/img/2ndicon-active.png' : '/img/2ndicon.png'}
                                                    alt="RentDirect Icon"
                                                    onMouseEnter={() => setHoveredTab('RentDirect')}
                                                    onMouseLeave={() => setHoveredTab(null)}
                                                />
                                                <span className="tab-tooltip"><span>rent</span>direct</span>
                                            </div>
                                        }
                                    >
                                        <RentDirect />
                                    </Tab>

                                    <Tab
                                        eventKey="Rentid"
                                        title={
                                            <div className="tab-title-container">
                                                <img
                                                    src={hoveredTab === 'Rentid' || activeKey === 'Rentid' ? '/img/3rdicon-active.png' : '/img/3rdicon.png'}
                                                    alt="Rentid Icon"
                                                    onMouseEnter={() => setHoveredTab('Rentid')}
                                                    onMouseLeave={() => setHoveredTab(null)}
                                                />
                                                <span className="tab-tooltip"><span>rent</span>ID</span>
                                            </div>
                                        }
                                    >
                                        <Rentid />
                                    </Tab>
                                    <Tab
                                        eventKey="rentshield"
                                        title={
                                            <div className="tab-title-container">
                                                <img
                                                    src={hoveredTab === 'rentshield' || activeKey === 'rentshield' ? '/img/4thicon-active.png' : '/img/4thicon.png'}
                                                    alt="rentshield Icon"
                                                    onMouseEnter={() => setHoveredTab('rentshield')}
                                                    onMouseLeave={() => setHoveredTab(null)}
                                                />
                                                <span className="tab-tooltip"><span>rent</span>shield</span>
                                            </div>
                                        }
                                    >
                                        <RentShield />
                                    </Tab>
                                    <Tab
                                        eventKey="RentSuccess"
                                        title={
                                            <div className="tab-title-container">
                                                <img
                                                    src={hoveredTab === 'RentSuccess' || activeKey === 'RentSuccess' ? '/img/5thicon-active.png' : '/img/5thicon.png'}
                                                    alt="RentSuccess Icon"
                                                    onMouseEnter={() => setHoveredTab('RentSuccess')}
                                                    onMouseLeave={() => setHoveredTab(null)}
                                                />
                                                <span className="tab-tooltip"><span>rent</span>success</span>
                                            </div>
                                        }
                                    >
                                        <RentSuccess />
                                    </Tab>
                                    <Tab
                                        eventKey="RentAcademy"
                                        title={
                                            <div className="tab-title-container">
                                                <img
                                                    src={hoveredTab === 'RentAcademy' || activeKey === 'RentAcademy' ? '/img/6thicon-active.png' : '/img/6thicon.png'}
                                                    alt="RentAcademy Icon"
                                                    onMouseEnter={() => setHoveredTab('RentAcademy')}
                                                    onMouseLeave={() => setHoveredTab(null)}
                                                />
                                                <span className="tab-tooltip"><span>rent</span>academy</span>
                                            </div>
                                        }
                                    >
                                        <RentAcademy />
                                    </Tab>
                                </Tabs>
                            </div>
                        </div>
                    </div>
                </div> */}
                </div>
            </section>
            {/* <section class="slide-text-sec">
                <div class="container-fluid">
                <div class="col-md-12 main-heading-text text-center">
               
				  <h3><marquee width="100%" behavior="scroll" direction="left" scrollamount="7">
                <span class="infinite-txt">From on-board to on-rent in less than 60 minutes.   From on-board to on-rent in less than 60 minutes.     </span>
                <span class="infinite-txt">From on-board to on-rent in less than 60 minutes.   From on-board to on-rent in less than 60 minutes.     </span>
                <span class="infinite-txt">From on-board to on-rent in less than 60 minutes.   From on-board to on-rent in less than 60 minutes.     </span>
                <span class="infinite-txt">From on-board to on-rent in less than 60 minutes.   From on-board to on-rent in less than 60 minutes.     </span>
                <span class="infinite-txt">From on-board to on-rent in less than 60 minutes.   From on-board to on-rent in less than 60 minutes.     </span>
                <span class="infinite-txt">From on-board to on-rent in less than 60 minutes.   From on-board to on-rent in less than 60 minutes.     </span>
            <div></div></marquee></h3>

              </div>
                </div>
         
            </section> */}
        </>
    );
}

export default AnimationSection;
