import React from 'react';
import { Box, Button, Container, Grid, Typography } from '@mui/material';
import logo from '../../assets/images/logo_new.png';
import arrow_down from "../../assets/images/arrow-down-01.png"
import red from "./assets/images/rent-shield-logo.svg";
import redalert from "../../assets/images/redhover.png";
import rend_id2 from "../../assets/images/rend_id2.png";
import { Link } from 'react-router-dom';
import RentShieldAnimation from './RentshieldAnimation';
import Insurance from './Insurance/Insurance';
import LiabilityInsurance from './Liabilityinsurance/LiabilityInsurance';
import PhysicalDamage from './PhysicalDamage/PhysicalDamage';
import Supplemental from './Supplemental/Supplemental';
import NationalRoadside from './NationalRoadside/NationalRoadside';
import RentHeader from '../RentHeader/RentHeader';
import BetaForm from '../BetaForm';
const RentShield2 = () => {

  return (
    <>
    
    <Box
        sx={{
          background: 'linear-gradient(180deg, #082C72 0%, #001132 55%, #000000 100%)',
          height: '100vh',
          width: '100%',
        }}
      >
        <Container>
          
      <RentHeader/>


{/* Banner section start from here */}

        <Box sx={{marginTop:'10%'}}>
        <Grid container spacing={5}>
          <Grid item md={5}>
            <Box  sx={{paddingTop:'4%'}}>
            <img src={red} alt=""/>
            <Typography sx={{fontSize:'17px',
            width:'542px',
            paddingTop:'5%',
             fontFamily:'Manrope', 
             fontWeight:700,
              color:'#FFFFFF'}}>EMBEDDED INSURANCE AND PROTECTION PLANS</Typography>
              <Typography sx={{
                fontSize:'36px',
              paddingTop:'5%',
              fontFamily:'Manrope', 
             fontWeight:600,
              color:'#FFFFFF',
              lineHeight:'45px'
              }}>
              <span style={{background:'linear-gradient(90.02deg,#b9fcf4 3.13%,#e9c9fc 66.57%)', color:'black' , borderRadius:'4PX', display:'inline-block' , lineHeight:'50px', paddingRight:'4px',paddingLeft:'1px'}}>Coverage, protection</span> & peace of mind for revenue generating assets
              </Typography>
              <Typography sx={{
                  width:'430px',
                  fontSize:'20px',
                  paddingTop:'5%',
                  fontFamily:'Manrope', 
                   fontWeight:300,
                    color:'#FFFFFF',
                    lineHeight:'25px'
              }}>
             Rent your fleet confidently while maintaining compliance, legality, and sustainable growth.
              </Typography>
            </Box>
        </Grid>

        <Grid item md={7}>
            {/* <img src={redalert} alt=''/> */}
            <RentShieldAnimation/>
        </Grid>
       </Grid>
        </Box>
        
        </Container>
      </Box>
      {/*<Insurance />
      <LiabilityInsurance/>
      <PhysicalDamage/>
      <Supplemental />
      <NationalRoadside/>*/}
      <BetaForm/>
      </>
      
  )
}

export default RentShield2;
