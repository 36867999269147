import React from 'react';
import { Box, Button, Container, Grid, Typography , display} from '@mui/material';
import logo from '../../assets/images/logo_new.png';
import arrow_down from "../../assets/images/arrow-down-01.png";
import rent_success from "./assets/rent-success-logo.svg";
import success_purple from "../../assets/images/success_purple.png";
import { Link } from 'react-router-dom';
import Rentsuccessanimation from './Rentsuccessanimation';
import RentHeader from '../RentHeader/RentHeader';
import BetaForm from '../BetaForm';
const RentSuccess2 = () => {


  return (
  <>
      <Box
        sx={{
          background: 'linear-gradient(180deg, #082C72 0%, #001132 55%, #000000 100%)',
          width: '100%',
        }}
      >
        <Container>
       <RentHeader />


{/* Banner section start from here */}

        <Box sx={{marginTop:'10%'}}>
        <Grid container spacing={5}>
          <Grid item md={5}>
            <Box>
            <img src={rent_success}></img>
            <Typography sx={{fontSize:'17px',
            width:'390px',
            paddingTop:'5%',
             fontFamily:'Manrope', 
             fontWeight:700,
              color:'#FFFFFF'}}>CUSTOMER AND TECHNICAL  SUPPORT</Typography>
              <Typography sx={{
                width:'475px',
                fontSize:'42px',
             paddingTop:'5%',
             fontFamily:'Manrope', 
             fontWeight:600,
              color:'#FFFFFF',
              lineHeight:'52px'
              }}>
            <span style={{background:'linear-gradient(90.02deg,#b9fcf4 3.13%,#e9c9fc 66.57%)', color:"black", borderRadius:'4px', padding:'px',  fontWeight:800, lineHeight:'48px' ,  marginTop:'5px' , padding:'0 2px' , display:'inline-block'}}>Live & automated </span> rental support when needed 
              </Typography>

              <Typography sx={{
                marginTop:'4%',
                width:'371px',
                  fontSize:'19px',
             paddingTop:'5%',
             fontFamily:'Manrope', 
             fontWeight:300,
              color:'#FFFFFF',
              lineHeight:'25px'
              }}>
            The <small style={{fontWeight:600}}>rentid</small> support team for you and your guests. We’re always available via chat, text, phone, or email, and powered by humans or robots for quicker service.
              </Typography>
            </Box>
        </Grid>

        <Grid item md={7} sx={{paddingBottom:''}}>
            {/* <img src={success_purple} alt=''/> */}
            <Rentsuccessanimation />
        </Grid>
       </Grid>
        </Box>
        
        </Container>
      </Box>
      <BetaForm/>
  </>
  )
}

export default RentSuccess2
