import React, { useContext, useState } from "react";
import "./Betasignup.css";
// import { Col, Container, Row } from 'react-bootstrap'
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import BetaForm from "../BetaForm";
import { UserContext } from "../../context/FormDataContext";
import log_anima from "../../assets/images/white_loader.gif";
import rent_loader from "../../assets/images/Loader_Rentid_White.svg"
import AnimationComponent from './loader_animation.jsx'
const Betasignup = () => {
  const { userData } = useContext(UserContext);
  const [loading, setLoading] = useState(false); // State for loader

  const handleContinueToBeta = () => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false); 
      const modal = new window.bootstrap.Modal(document.getElementById('exampleModal'));
      modal.show(); 
    }, 2000); 
  };

  return (
    <div>
      <main className="main beta-signup">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12 col-xs-12 col-md-12 col-lg-4 p-0">
              <div className="banner-img">
                <div className="row">
                  <div className="col-12 p-0">
                    <div className="row">
                      <div className="col-12 p-md-0 main-logo">
                        <a href="/">
                          <img
                            src="/img/beta-signup/Rentid-white-logo.svg"
                            className="img-fluid d-none d-lg-block"
                            alt="feature img"
                          />
                        </a>
                        <a href="/">
                          <img
                            src="/img/beta-signup/mobile-logo.png"
                            className="img-fluid d-lg-none"
                            alt="feature img"
                          />
                        </a>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-12 p-md-0 mt-3 mt-md-0">
                        <div className="img-section text-center pb-2">
                         <AnimationComponent/>
                        </div>
                        <p className="congrats-text">Congratulations</p>
                        <div className="row details-section">
                          <div className="col-12 p-0 px-md-3">
                            <p className="user-name text-center">
                              {userData.firstName} {userData.lastName} <br />
                              {userData.companyName}
                            </p>
                            <p className="thank-you-text">
                              Thank you for joining our waitlist!
                            </p>
                            <p className="desc-notes mb-2 mb-md-5">
                              We're excited to have you on this journey with us!
                              Please check your email for a confirmation and
                              further details. We'll keep you updated with the
                              latest news and developments as we get closer to
                              launch.
                            </p>
                            <p className="desc-notes mb-3">
                              In the meantime, feel free to share Rentid with
                              your friends - We can't wait to welcome everyone!
                            </p>
                            <h3 className="rent-team">-Team Rentid</h3>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-xs-12 col-md-12 col-lg-8 p-0">
              <a href="/">
                <img
                  src="/img/beta-signup/Close-X.png"
                  className="img-fluid cross-icon"
                  alt="close"
                />
              </a>
              <div className="row center-row align-items-center">
                <div className="col-12 text-md-center">
                  <p className="ready-text mb-4">Ready for early access?</p>
                  <p className="continue-signup mb-4">
                    Continue and sign up for beta
                  </p>
                  <p className="time-takes mb-4">
                    Takes approximately 5 minutes
                  </p>
                  <button
                    type="button"
                    className="btn btn-radius"
                    onClick={handleContinueToBeta}
                  >
                    Continue to beta
                  </button>

                  {/* loader  */}
                  {loading && (
          <div className="loader-background">
            <div className="loader">
              <img src={rent_loader} alt="Loading" />
              <p>Loading...</p>
            </div>
          </div>
        )}
                </div>
              </div>
              <div className="row footer-section">
                <div className="col-12 text-center">
                  <p className="leagal-footer">
                    <span>
                      <img
                        src="/img/beta-signup/elements.png"
                        className="img-fluid"
                        alt="copy right"
                      />
                      2024 Rentid Inc.
                    </span>
                    <span className="ms-3 footer-link">
                      <a href="/terms" target="_blank">Legal</a>
                    </span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>


        <BetaForm loading={loading} /> 
      </main>
    </div>

  );
};

export default Betasignup;
