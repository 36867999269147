import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Button, Container, Grid, Typography } from '@mui/material';
import logo from '../../assets/images/logo_new.png';
import arrow_down from "../../assets/images/arrow-down-01.png"
import rentdirect from "../../assets/images/rentdirect.png";
import rend_id from "./assets/images/rent-id-logo.svg";
import rend_id2 from "../../assets/images/rend_id2.png";
import RentIdanimation from './RentIdanimation'
import RentHeader from '../RentHeader/RentHeader';
import RiskManagement from "./RiskManagement/RiskManagement";
import LoyalGuest from './LoyalGuest/LoyalGuest';
import BetaForm from '../BetaForm';
const RentId2 = () => {
const navigate = useNavigate();


  return (
   <>
  
     <Box
        sx={{
          background: 'linear-gradient(180deg, #082C72 0%, #001132 55%, #000000 100%)',
          height: '100vh',
          width: '100%',
        }}
      >
        <Container>
        <RentHeader />


{/* Banner section start from here */}

        <Box sx={{marginTop:'10%'}}>
        <Grid container spacing={5} xs={12}>
          <Grid item md={5} xs={12}>
            <Box>
            <img src={rend_id}></img>
            <Typography sx={{fontSize:'17px',
            width:'390px',
            paddingTop:'5%',
             fontFamily:'Manrope', 
             fontWeight:700,
              color:'#FFFFFF'}}>ADVANCED TRUST AND SAFETY TOOLS</Typography>
              <Typography sx={{
                fontSize:'42px',
                width:'396px',
             paddingTop:'5%',
             fontFamily:'Manrope', 
             fontWeight:600,
              color:'#FFFFFF',
             lineHeight:'65px'
              }}>
            Outsmart risk with built-in <span style={{background:'linear-gradient(90.02deg,#b9fcf4 3.13%,#e9c9fc 66.57%)', color:"black", borderRadius:'4px', padding:'0 5px',  fontWeight:'600' , lineHeight:'42px'}}>security</span> <span style={{background:'linear-gradient(90.02deg,#b9fcf4 3.13%,#e9c9fc 66.57%)', color:"black", borderRadius:'4px', padding:'0 5px',  fontWeight:'600' , lineHeight:'42px'}}>measures.</span> 
              </Typography>
              <Typography sx={{
                width:'371px',
                  fontSize:'20px',
             paddingTop:'5%',
             fontFamily:'Manrope', 
             fontWeight:300,
              color:'#FFFFFF',
              lineHeight:'25px'
              }}>
              Use data-driven decision-making to protect your account, vehicles, and future earnings. You alone control who gets the keys.
              </Typography>
            </Box>
        </Grid>

        <Grid item md={7}>
            {/* <img src={rend_id2} alt=''/> */}
        <RentIdanimation />
        </Grid>
       </Grid>
        </Box>
        
        </Container>
      </Box>
      {/*<RiskManagement/>
      <LoyalGuest />*/}
      <BetaForm/>
   </>
  )
}

export default RentId2
