import * as Yup from 'yup';
let ppt = true;
export const validationSchemas = [
  
  Yup.object({
    first_name: Yup.string().required('First name is required'),
    last_name: Yup.string().required('Last name is required'),
    phone_number: Yup.string()
      .required('Phone number is required'),
      // .matches(
      //   /^\(\d{3}\) \d{3}-\d{4}$/,
      //   'Phone number is required'
      // ),
    // email_address: Yup.string().email('Invalid email address').required('Email is required'),
    // email_address: Yup.string().email('Email address is not in the correct format please enter it correctly').required('Email is required'),
    email_address: Yup.string()
      .email('Email address is not in the correct format please enter it correctly')
      .required('Email is required')
      .test('has-period-after-at', 'Domain part must include a period.', value => {
        if (!value) return false;
        const [localPart, domainPart] = value.split('@');
        return domainPart && domainPart.includes('.');
      }),
    state: Yup.string().required('State is required'),
    fleet_size: Yup.string().required('Fleet size is required'),
  }),
  Yup.object({
    business_compliance: Yup.string().required('Please select an option'),
  }),
  Yup.object({
    regulations_compliance: Yup.string().required('Please select an option'),
  }),
  Yup.object({
    manage_business: Yup.array()
    .of(Yup.string())
    .min(1, 'Please select at least one option') 
    .required('This field is required')
    .test(
      'platform-validation',
      'Please select at least one platform option',
      function (value) {
       console.log(value,'this is value')
        // If "3" is selected, validate platform options
        if (value && value.includes('3')) {
      
          const platformsSelected = this.parent.selectedPlatforms || [];
          console.log(platformsSelected,'this is platform')
          const platformname = this.parent.getaround;
           console.log(this.parent.getaround,'addy');
           console.log(platformname,'this is platformname')
        if (this.parent.getaround) {
            return platformname.length > 0;
          }else{
            return true; 
          }
        }

        
        return true;
      }
    )
    .test(
      'software-validation',
      'Please select at least one software option',
      function (value) {
        if (value && value.includes('4')) {
      
          const softwareSelected = this.parent.selectedSoftware || [];
          console.log(this.parent.getaround,'this is software')
          const softwarename = this.parent.softwarerental;
           console.log(softwarename,'this is softwarename')
        if (this.parent.softwarerental) {
            return softwarename.length > 0;
          }else{
            return true; 
          }
        }

        
        return true;
      }
    ),


    platforms_option_detail_Get_Around: Yup.object()
    .nullable()
    .required('This field is required')
    .test('key-is-true', 'Key must be true', function (value) {
      let key_v='';
      if (this.parent.getaround && this.parent.getaround.includes('Get Around')) {
         key_v='false';
      } else {
        key_v='true';
      }
      let updatedValue = {Get_around:'',key:''};
   
        Object.keys(value).forEach((key) => {
          updatedValue[key === "Get Around" ? "Get_around" : key] = value[key];
        });
        updatedValue.key = key_v;

        //console.log('Updated value:', updatedValue);
      if (value && Object.keys(value).length > 0 && updatedValue.key !== 'true'  && Object.keys(updatedValue.Get_around).length === 0 ) {
        //console.log('false1');
        return this.createError({ message: 'This field is required' });
      }

      return true;  // Object is valid
    }),

      
 platforms_option_detail_Hyrecar: Yup.object()
    .nullable()
    .required('This field is required')
    .test('key-is-true', 'Key must be true', function (value) {
      let key_v='';
      if (this.parent.getaround && this.parent.getaround.includes('Hyrecar')) {
         key_v='false';
      } else {
        key_v='true';
      }
      let updatedValue = {Hyrecar:'',key:''};
          
        Object.keys(value).forEach((key) => {
        updatedValue[key === "Get Around" ? "Get_around" : key] = value[key];
        });
        updatedValue.key = key_v;


      if (value && Object.keys(value).length > 0 && updatedValue.key !== 'true'  && Object.keys(updatedValue.Hyrecar).length === 0 ) {
        //console.log('false1');
        return this.createError({ message: 'This field is required' });
      }

      return true;  // Object is valid
    }),
  
      




    



    

// platforms_option_detail_Get_Around: Yup.object({
//   manage_business: Yup.array().required('This field is required'),
//   platform_detail: Yup.string()
//   .test('not-empty', 'This field is required', function (value) {
//            return value !== null && Object.keys(value || {}).length > 0;
//          }),
// }),
    
   

    platforms_option_detail_Navotar: Yup.object()
    .nullable()
    .required('This field is required')
    .test('key-is-true', 'Key must be true', function (value) {
      let key_v='';
      if (this.parent.getaround && this.parent.getaround.includes('Navotar')) {
         key_v='false';
      } else {
        key_v='true';
      }
      let updatedValue = {Navotar:'',key:''};
   
Object.keys(value).forEach((key) => {
updatedValue[key === "Get Around" ? "Get_around" : key] = value[key];
});
updatedValue.key = key_v;

//console.log('Updated value:', updatedValue);
      if (value && Object.keys(value).length > 0 && updatedValue.key !== 'true'  && Object.keys(updatedValue.Navotar).length === 0 ) {
        //console.log('false1');
        return this.createError({ message: 'This field is required' });
      }

      return true;  // Object is valid
    }),
    

    platforms_option_detail_Other: Yup.object()
    .nullable()
    .required('This field is required')
    .test('key-is-true', 'Key must be true', function (value) {
      let key_v='';
      if (this.parent.getaround && this.parent.getaround.includes('Other')) {
         key_v='false';
      } else {
        key_v='true';
      }
      let updatedValue = {Other:'',key:''};
   
Object.keys(value).forEach((key) => {
updatedValue[key === "Get Around" ? "Get_around" : key] = value[key];
});
updatedValue.key = key_v;

//console.log('Updated value:', updatedValue);
      if (value && Object.keys(value).length > 0 && updatedValue.key !== 'true'  && Object.keys(updatedValue.Other).length === 0 ) {
        //console.log('false1');
        return this.createError({ message: 'This field is required' });
      }

      return true;  // Object is valid
    }),

    


    platforms_option_detail_Turo: Yup.object()
    .nullable()
    .required('This field is required')
    .test('key-is-true', 'Key must be true', function (value) {
      console.log(value);
      let key_v='';
      if (this.parent.getaround && this.parent.getaround.includes('Turo')) {
         key_v='false';
      } else {
        key_v='true';
      }
      let updatedValue = {Turo:'',key:''};
   
Object.keys(value).forEach((key) => {
updatedValue[key === "Get Around" ? "Get_around" : key] = value[key];
});
updatedValue.key = key_v;

//console.log('Updated value:', updatedValue);
      if (value && Object.keys(value).length > 0 && updatedValue.key !== 'true'  && Object.keys(updatedValue.Turo).length === 0 ) {
        //console.log('false1');
        return this.createError({ message: 'This field is required' });
      }

      return true;  // Object is valid
    }),



    // software options validation start from here..
    software_option_detail_HQ_Rental_Software: Yup.object()
    .nullable()
    .required('This field is required')
    .test('key-is-true', 'Key must be true', function (value) {
      console.log(value);
      let key_v='';
      if (this.parent.softwarerental && this.parent.softwarerental.includes('HQ Rental Software')) {
         key_v='false';
      } else {
        key_v='true';
      }
      let updatedValue = {HQ_Rental_Software:'',key:''};
   
    Object.keys(value).forEach((key) => {
    updatedValue[key === "HQ Rental Software" ? "HQ_Rental_Software" : key] = value[key];
    });
    updatedValue.key = key_v;

    console.log('Updated value:', updatedValue);
      if (value && Object.keys(value).length > 0 && updatedValue.key !== 'true'  && Object.keys(updatedValue.HQ_Rental_Software).length === 0 ) {
        //console.log('false1');
        return this.createError({ message: 'This field is required' });
      }

      return true;  // Object is valid
    }),




    software_option_detail_RentWorks: Yup.object()
    .nullable()
    .required('This field is required')
    .test('key-is-true', 'Key must be true', function (value) {
      console.log(value);
      let key_v='';
      if (this.parent.softwarerental && this.parent.softwarerental.includes('RentWorks')) {
         key_v='false';
      } else {
        key_v='true';
      }
      let updatedValue = {RentWorks:'',key:''};
   
Object.keys(value).forEach((key) => {
updatedValue[key === "Get Around" ? "Get_around" : key] = value[key];
});
updatedValue.key = key_v;

console.log('Updated value:', updatedValue);
      if (value && Object.keys(value).length > 0 && updatedValue.key !== 'true'  && Object.keys(updatedValue.RentWorks).length === 0 ) {
        //console.log('false1');
        return this.createError({ message: 'This field is required' });
      }

      return true;  // Object is valid
    }),


    

    software_option_detail_Navotar: Yup.object()
    .nullable()
    .required('This field is required')
    .test('key-is-true', 'Key must be true', function (value) {
      console.log(value);
      let key_v='';
      if (this.parent.softwarerental && this.parent.softwarerental.includes('Navotar')) {
         key_v='false';
      } else {
        key_v='true';
      }
      let updatedValue = {Navotar:'',key:''};
   
Object.keys(value).forEach((key) => {
updatedValue[key === "Get Around" ? "Get_around" : key] = value[key];
});
updatedValue.key = key_v;

console.log('Updated value:', updatedValue);
      if (value && Object.keys(value).length > 0 && updatedValue.key !== 'true'  && Object.keys(updatedValue.Navotar).length === 0 ) {
        //console.log('false1');
        return this.createError({ message: 'This field is required' });
      }

      return true;  // Object is valid
    }),



    
    software_option_detail_Rent_Centric: Yup.object()
    .nullable()
    .required('This field is required')
    .test('key-is-true', 'Key must be true', function (value) {
      console.log(value);
      let key_v='';
      if (this.parent.softwarerental && this.parent.softwarerental.includes('Rent Centric')) {
         key_v='false';
      } else {
        key_v='true';
      }
      let updatedValue = {Rent_Centric:'',key:''};
   
Object.keys(value).forEach((key) => {
updatedValue[key === "Rent Centric" ? "Rent_Centric" : key] = value[key];
});
updatedValue.key = key_v;

console.log('Updated value:', updatedValue);
      if (value && Object.keys(value).length > 0 && updatedValue.key !== 'true'  && Object.keys(updatedValue.Rent_Centric).length === 0 ) {
        //console.log('false1');
        return this.createError({ message: 'This field is required' });
      }

      return true;  // Object is valid
    }),





        
//     software_option_detail_Other: Yup.object()
//     .nullable()
//     .required('This field is required')
//  ,
    
//   }),


    software_option_detail_Other: Yup.object()
    .nullable()
    .required('This field is required')
	 .test('key-is-true', 'Key must be true', function (value) {
      let key_v='';
      if (this.parent.softwarerental && this.parent.softwarerental.includes('Other')) {
         key_v='false';
      } else {
        key_v='true';
      }
      let updatedValue = {Other:'',key:''};
   
Object.keys(value).forEach((key) => {
updatedValue[key === "Get Around" ? "Get_around" : key] = value[key];
});
updatedValue.key = key_v;

//console.log('Updated value:', updatedValue);
      if (value && Object.keys(value).length > 0 && updatedValue.key !== 'true'  && Object.keys(updatedValue.Other).length === 0 ) {
        //console.log('false1');
        return this.createError({ message: 'This field is required' });
      }

      return true;  // Object is valid
    }),
 
    
  }),

  

  




  Yup.object({
    using_telematics: Yup.string().required('This field is required.'),
    selected_telematics: Yup.mixed().when("using_telematics", {
      is: (vesselCategoryCode) => vesselCategoryCode ==0,
      then: () => Yup.array().min(1, 'Please select at least one telematics device.').required(),
      otherwise:() => Yup.array().notRequired('This field is  not required.'),
    }) 
  }),



  Yup.object({
    automated_verification: Yup.string().required('ID verification selection is required'),
  }),
  Yup.object({
    automated_validation: Yup.string().required('Please select an option'),
  }),
  Yup.object({
    marketing_services: Yup.string().required('Please select an option'),
  }),
  Yup.object({
    renting_vehicles: Yup.string().required('Please select an option for vehicle rental interest'),
  }),
  Yup.object({
    insurance_provider: Yup.string().required('This field is required.'),
    selected_insurance_providers: Yup.mixed().when("insurance_provider", {
      is: (vesselCategoryCode) => vesselCategoryCode ==0,
      then: () => Yup.array().min(1, 'Please select at least one').required(),
      otherwise:() => Yup.array().notRequired('This field is  not required.'),
    }) 
  }),
  Yup.object({
    on_rent_insurance: Yup.string().required('Please select an option'),
  }),
  Yup.object({
    learn_deliverless: Yup.string().required('Please select an option'),
  }),
];
