import React, { useEffect } from 'react';
 // Assuming your CSS is in a separate file named styles.css
import img1 from './assets/images/security-check.svg';
import img2 from './assets/images/user-shield-01.svg';
import img3 from './assets/images/web-protection.svg';
import img4 from './assets/images/settings-01.svg';
import img5 from './assets/images/tow-truck.svg';
import img6 from './assets/images/shield.svg';
import img7 from './assets/images/mentoring.svg'
import rentshieldanimation from './rentshieldanimation.css';
const RentShieldAnimation = () => {
  
  useEffect(() => {
    const container = document.querySelector('.container');
    const wrappers = document.querySelector('.wrappers');

    const images = [
      img1, img2, img3, img4, img5,img6,img7
    ];

    // Generating the Card for using Array
    const elementsData = [
      "CommercialInsurance",
      "InsuranceServices ",
      "ProtectionPlans",
      "CoverageSettings",
      "RoadsideAssistance",
      "RentShield",
      "ClaimsManagement"
     
    ];

    Array.from({ length: 36 }).forEach((_, index) => {
      const elements = document.createElement('div');
      elements.classList.add('items', `item-${index + 1}`);

      if (elementsData[index]) {
        const title = document.createElement('span');
       // title.innerHTML = elementsData[index];

         
       let camelCaseText = title.innerHTML; // Extract the current text
       camelCaseText = elementsData[index]; // Update the variable
   let formattedText = camelCaseText.replace(/([A-Z])/g, ' $1').trim();
       title.innerHTML =  formattedText; 

         const img = document.createElement('img');
        img.src = images[index % images.length]; // Cycle through images
        img.classList.add('icon');

        if (elementsData[index] === "RentShield") {
          elements.classList.add('rentshield-card'); // Add custom class here
        }

        elements.append(img);
        elements.append(title);
        elements.style.gridArea = elementsData[index];
        elements.setAttribute('data-name', elementsData[index]);

        wrappers.append(elements);
      }
    });

    // Generating SVG Line for each Element
    const elementsDataName = [
      ["ProtectionPlans", "CommercialInsurance"],
      ["RentShield", "CoverageSettings"],
      ["RentShield", "RoadsideAssistance"],
      // ["CommercialInsurance", "InsuranceServices "],
      ["InsuranceServices ", "CommercialInsurance"],
      ["RentShield", "ClaimsManagement"],
   
    ];

    // Generating SVGs inside the .container
    elementsDataName.forEach((_, index) => {
      const svgContainer = document.createElementNS("http://www.w3.org/2000/svg", "svg");
      svgContainer.classList.add('svg-lines1', `svg-lines1-${index + 1}`);
      container.appendChild(svgContainer);
    });

    // Function to create SVG paths
    const createSVGPath = (startX, startY, endX, endY) => {
      const svgNS = "http://www.w3.org/2000/svg";
      const path = document.createElementNS(svgNS, "path");
      const pathData = `
        M ${startX} ${startY}
        L ${startX} ${endY}
        L ${endX} ${endY}
        L ${endX} ${endY}
      `;
      path.setAttribute("d", pathData);
      path.setAttribute("stroke", "orange");
      path.setAttribute("stroke-width", "3");
      path.setAttribute("fill", "none");
      path.classList.add("animate-path");

      return path;
    };

    const connectElements = () => {
      elementsDataName.forEach((name, index) => {
        const svgContainer = document.querySelector(`.svg-lines1-${index + 1}`);
        const [startName, endName] = name;

        const item1 = document.querySelector(`[data-name='${startName}']`);
        const item2 = document.querySelector(`[data-name='${endName}']`);

        // Clear previous SVG content
        svgContainer.innerHTML = "";

        // Get positions and dimensions
        const rect1 = item1.getBoundingClientRect();
        const rect2 = item2.getBoundingClientRect();

        // Calculate start and end points
        const startX = rect1.left + rect1.width / 2;
        const startY = rect1.top + rect1.height / 2;
        const endX = rect2.left + rect2.width / 2;
        const endY = rect2.top + rect2.height / 2;

        // Append path to SVG container
        svgContainer.appendChild(createSVGPath(startX, startY, endX, endY));
      });
    };

    const drawLines = () => {
      connectElements();
      const paths = document.querySelectorAll('.animate-path');

      paths.forEach((path) => {
        const pathLength = path.getTotalLength();
        path.style.strokeDasharray = pathLength;
        path.style.strokeDashoffset = pathLength;
        path.style.setProperty('--path-length', `${pathLength}px`);
      });
    };

    const animatingLine = async () => {
      const path1 = document.querySelector('.svg-lines1-1 path');
      const path2 = document.querySelector('.svg-lines1-2 path');
      const path3 = document.querySelector('.svg-lines1-3 path');
      const path4 = document.querySelector('.svg-lines1-4 path');
      const path5 = document.querySelector('.svg-lines1-5 path');
      const path6 = document.querySelector('.svg-lines1-6 path');
      const InsuranceServices  = document.querySelector("[data-name='InsuranceServices ']");
      const CommercialInsurance = document.querySelector("[data-name='CommercialInsurance']");
      const ProtectionPlans = document.querySelector("[data-name='ProtectionPlans']");
      const CoverageSettings = document.querySelector("[data-name='CoverageSettings']");
      const RoadsideAssistance = document.querySelector("[data-name='RoadsideAssistance']");
      const RentShield = document.querySelector("[data-name='RentShield']");
      const ClaimsManagement = document.querySelector("[data-name='ClaimsManagement']");
      
      async function animateCards() {
        while (true) {
            // First Animation Sequence
            eachAnimation(path1, ProtectionPlans, CommercialInsurance, "add");
            await delay(2000);
            eachAnimation(path1, ProtectionPlans, CommercialInsurance, "remove");
            
            eachAnimation(path2, RentShield, CoverageSettings, "add");
            await delay(2000);
            eachAnimation(path2, RentShield, CoverageSettings, "remove");

            eachAnimation(path3, RentShield, RoadsideAssistance, "add");
            await delay(2000);
            eachAnimation(path3, RentShield, RoadsideAssistance, "remove");


            eachAnimation(path4, InsuranceServices, CommercialInsurance , "add");
            await delay(2000);
            eachAnimation(path4, InsuranceServices, CommercialInsurance , "remove");

            eachAnimation(path5, RentShield, ClaimsManagement, "add");
            await delay(2000);
            eachAnimation(path5, RentShield, ClaimsManagement, "remove");


            // eachAnimation(path5, RentShield, ClaimsManagement, "add");
            // await delay(2000);
            // eachAnimation(path5, RentShield, ClaimsManagement, "remove");

            
            // eachAnimation(path6, RentShield, ProtectionPlans, "add");
            // await delay(2000);
            // eachAnimation(path6, RentShield, ProtectionPlans, "remove");


    
          
        }
    }
    function delay(ms) {
      return new Promise((resolve) => setTimeout(resolve, ms));
  }
  animateCards();
    };
    

    const eachAnimation = (path, card1, card2, action) => {

      const text1 = card1.querySelector('span');
      const text2 = card2.querySelector('span');


      if (action === "add") {
        path.classList.add("animate-now1");
        card1.classList.add("animate-now1");
        // card2.classList.add("animate-now1-delay");
        setTimeout(() => {
          card2.classList.add('animate-now1-delay');
      }, 1100);

        if (text1) text1.classList.add("show-text");
        if (text2) text2.classList.add("show-text");
      } else if (action === "remove") {
        path.classList.remove("animate-now1");
        card1.classList.remove("animate-now1");
        card2.classList.remove("animate-now1-delay");
      }
    };

    // Initial drawing and animation
    drawLines();
    animatingLine();

    // Event listeners
    //window.addEventListener("resize", connectElements);

    // Cleanup function
    return () => {
      window.removeEventListener("resize", connectElements);
      // Clean up any SVGs or elements created if necessary
    };
  }, []); // Empty dependency array to run only on mount

  return (
    <div className="container">
    
      <div className="wrappers"></div>
    </div>
  );
};

export default RentShieldAnimation;
