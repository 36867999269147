// IdVerification.js
import React from 'react';
import { Field, ErrorMessage } from 'formik';

const IdVerification = () => {
  return (
    <div className="h-100">
      <div className="row h-100 align-items-center">
        <div className="col-12 col-xs-12 col-md-12 px-0">
          <div className="general-info-form py-5">
            <div className="row">
              <div className="col-12 p-0">
                <p className="llc-text pb-3">Do you currently use automated ID verification services for your guests?</p>
              </div>
            </div>
            <div className="row">
              <div className="col-12 col-xs-12 col-md-6 p-0 pe-md-2">
                <div className="form-check ps-0">
                  <Field type="radio" id="logoIcon" name="automated_verification" value="1" className="form-check-input" />
                  <label className="form-check-label form-check-field w-100" htmlFor="logoIcon">
                    <div className="row">
                      <div className="col-12 p-0">
                        <div className="row">
                          <div className="col-10 ps-0">
                            <img src="/img/beta-signup/logo-icon.svg" className="img-fluid" alt="llc" />
                          </div>
                        </div>
                        <div className="row mt-3">
                          <div className="col-12 ps-0">
                            <p className="llc-heading mb-0">Yes</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </label>
                </div>
              </div>
              <div className="col-12 col-xs-12 col-md-6 mt-3 p-0 ps-md-2 mt-md-0">
                <div className="form-check ps-0">
                  <Field type="radio" id="visuallyInspect" name="automated_verification" value="0" className="form-check-input" />
                  <label className="form-check-label form-check-field w-100" htmlFor="visuallyInspect">
                    <div className="row">
                      <div className="col-12 p-0">
                        <div className="row">
                          <div className="col-10 ps-0">
                            <img src="/img/beta-signup/student-card.svg" className="img-fluid" alt="llc" />
                          </div>
                        </div>
                        <div className="row mt-3">
                          <div className="col-12 ps-0">
                            <p className="llc-heading mb-0">I visually inspect the ID</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </label>
                </div>
              </div>
            </div>
            <ErrorMessage name="automated_verification">{msg => <div className="invalid-feedback">{msg}</div>}</ErrorMessage>
          </div>
        </div>
      </div>
    </div>
  );
};

export default IdVerification;
