// DeliveryDrivers.js
import React from 'react';
import { Field, ErrorMessage } from 'formik';

const DeliveryDrivers = () => {
  return (
    <div className="h-100">
      <div className="row h-100 align-items-center">
        <div className="col-12 col-xs-12 col-md-12 px-0">
          <div className="general-info-form py-5">
            <div className="row">
              <div className="col-12 p-0">
                <p className="llc-text pb-3">
                Would you be interested in renting vehicles to drivers for ride sharing services like Uber/Lift or delivery like DoorDash?
                </p>
              </div>
            </div>
            <div className="row">
              <div className="col-12 col-xs-12 col-md-6 p-0 pe-md-2">
                <div className="form-check ps-0">
                  <Field
                    type="radio"
                    id="deliveryDriversYes"
                    name="renting_vehicles"
                    value="1"
                    className="form-check-input"
                  />
                  <label
                    className="form-check-label form-check-field w-100"
                    htmlFor="deliveryDriversYes"
                  >
                    <div className="row">
                      <div className="col-12 p-0">
                        <div className="row">
                          <div className="col-10 ps-0">
                            <img
                              src="/img/beta-signup/package.svg"
                              className="img-fluid"
                              alt="llc"
                            />
                          </div>
                        </div>
                        <div className="row mt-3">
                          <div className="col-12 ps-0">
                            <p className="llc-heading mb-0">Yes</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </label>
                </div>
              </div>
              <div className="col-12 col-xs-12 col-md-6 mt-3 mt-md-0 p-0 ps-md-2">
                <div className="form-check ps-0">
                  <Field
                    type="radio"
                    id="deliveryDriversNo"
                    name="renting_vehicles"
                    value="0"
                    className="form-check-input"
                  />
                  <label
                    className="form-check-label form-check-field w-100"
                    htmlFor="deliveryDriversNo"
                  >
                    <div className="row">
                      <div className="col-12 p-0">
                        <div className="row">
                          <div className="col-10 ps-0">
                            <img
                              src="/img/beta-signup/package-not.svg"
                              className="img-fluid"
                              alt="llc"
                            />
                          </div>
                        </div>
                        <div className="row mt-3">
                          <div className="col-12 ps-0">
                            <p className="llc-heading mb-0">Maybe later</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </label>
                </div>
              </div>
              <div className="col-12">
                <ErrorMessage name="renting_vehicles" component="div" className="invalid-feedback" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DeliveryDrivers;
