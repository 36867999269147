import React, { useEffect } from 'react';
import './Rentsuccessanimation.css'; // Assuming your CSS is in a separate file named styles.css
import img1 from './assets/headset.svg';
import img2 from './assets/money-receive-circle.svg';
import img3 from './assets/shield-blockchain.svg';
import img4 from './assets/ai-computer.svg';
import img5 from './assets/rentsuccess.svg'
import img6 from './assets/computer.svg';
import img7 from './assets/setup-02.svg'
const Rentsuccessanimation = () => {
  
  useEffect(() => {
    const container1 = document.querySelector('.container1');
    const wrappers1 = document.querySelector('.wrappers1');

    const images = [
      img1, img2, img3, img4, img5,img6,img7
    ];

    // Generating the Card for using Array
    const elementsData = [
      "TechSupport",
      "SuccessManagement",
      "CrisisManagement",
      "ProactiveMonitoring",
      "RentSuccess",
      "AccountOnboarding",
      "TechnicalReview"
     
    ];

    Array.from({ length: 36 }).forEach((_, index) => {
      const elements = document.createElement('div');
      elements.classList.add('items4', `item-${index + 1}`);

      if (elementsData[index]) {
        const title = document.createElement('span');
        //title.innerHTML = elementsData[index];

        let camelCaseText = title.innerHTML; // Extract the current text
        camelCaseText = elementsData[index]; // Update the variable
	     	let formattedText = camelCaseText.replace(/([A-Z])/g, ' $1').trim();
         if(formattedText == 'Tech Support'){
          formattedText = '24/7 Support'
         }
        title.innerHTML =  formattedText;     // Update the DOM element
      
         const img = document.createElement('img');
        img.src = images[index % images.length]; // Cycle through images
        img.classList.add('icon');


        if (elementsData[index] === "RentSuccess") {
            elements.classList.add('rentsuccess-card'); // Add custom class here
          }
  

        elements.append(img);
        elements.append(title);
        elements.style.gridArea = elementsData[index];
        elements.setAttribute('data-name', elementsData[index]);

        wrappers1.append(elements);
      }
    });

    // Generating SVG Line for each Element
    const elementsDataName = [
     ["SuccessManagement", "TechSupport"],
     ["RentSuccess", "AccountOnboarding"],
     ["RentSuccess", "ProactiveMonitoring"],
     ["CrisisManagement", "TechSupport"],
     ["RentSuccess", "TechnicalReview"],
    ];

    // Generating SVGs inside the .container1
    elementsDataName.forEach((_, index) => {
      const svgcontainer1 = document.createElementNS("http://www.w3.org/2000/svg", "svg");
      svgcontainer1.classList.add('svg-lines2', `svg-lines2-${index + 1}`);
      container1.appendChild(svgcontainer1);
    });

    // Function to create SVG paths
    const createSVGPath = (startX, startY, endX, endY) => {
      const svgNS = "http://www.w3.org/2000/svg";
      const path = document.createElementNS(svgNS, "path");
      const pathData = `
        M ${startX} ${startY}
        L ${startX} ${endY}
        L ${endX} ${endY}
        L ${endX} ${endY}
      `;
      path.setAttribute("d", pathData);
      path.setAttribute("stroke", "orange");
      path.setAttribute("stroke-width", "3");
      path.setAttribute("fill", "none");
      path.classList.add("animate-path");

      return path;
    };

    const connectElements = () => {
      elementsDataName.forEach((name, index) => {
        const svgcontainer1 = document.querySelector(`.svg-lines2-${index + 1}`);
        const [startName, endName] = name;

        const item1 = document.querySelector(`[data-name='${startName}']`);
        const item2 = document.querySelector(`[data-name='${endName}']`);

        // Clear previous SVG content
        svgcontainer1.innerHTML = "";

        // Get positions and dimensions
        const rect1 = item1.getBoundingClientRect();
        const rect2 = item2.getBoundingClientRect();

        // Calculate start and end points
        const startX = rect1.left + rect1.width / 2;
        const startY = rect1.top + rect1.height / 2;
        const endX = rect2.left + rect2.width / 2;
        const endY = rect2.top + rect2.height / 2;

        // Append path to SVG container1
        svgcontainer1.appendChild(createSVGPath(startX, startY, endX, endY));
      });
    };

    const drawLines = () => {
      connectElements();
      const paths = document.querySelectorAll('.animate-path');

      paths.forEach((path) => {
        const pathLength = path.getTotalLength();
        path.style.strokeDasharray = pathLength;
        path.style.strokeDashoffset = pathLength;
        path.style.setProperty('--path-length', `${pathLength}px`);
      });
    };

    const animatingLine = async () => {
      const path1 = document.querySelector('.svg-lines2-1 path');
      const path2 = document.querySelector('.svg-lines2-2 path');
      const path3 = document.querySelector('.svg-lines2-3 path');
      const path4 = document.querySelector('.svg-lines2-4 path');
      const path5 = document.querySelector('.svg-lines2-5 path');
      const RentSuccess = document.querySelector("[data-name='RentSuccess']");
      const TechSupport = document.querySelector("[data-name='TechSupport']");
      const SuccessManagement = document.querySelector("[data-name='SuccessManagement']");
      const CrisisManagement = document.querySelector("[data-name='CrisisManagement']");
      const ProactiveMonitoring = document.querySelector("[data-name='ProactiveMonitoring']");
      const RentShield = document.querySelector("[data-name='RentShield']");
      const TechnicalReview = document.querySelector("[data-name='TechnicalReview']");
      const AccountOnboarding = document.querySelector("[data-name='AccountOnboarding']");
      


      async function animateCards() {
        while (true) {
            // First Animation Sequence
            eachAnimation(path1, SuccessManagement, TechSupport, "add");
            await delay(2000);
            eachAnimation(path1, SuccessManagement, TechSupport, "remove");

            eachAnimation(path2, RentSuccess, AccountOnboarding, "add");
            await delay(2000);
            eachAnimation(path2, RentSuccess, AccountOnboarding, "remove");

            eachAnimation(path3, RentSuccess, ProactiveMonitoring, "add");
            await delay(2000);
            eachAnimation(path3, RentSuccess, ProactiveMonitoring, "remove");
               
            eachAnimation(path4, CrisisManagement, TechSupport, "add");
            await delay(2000);
            eachAnimation(path4, CrisisManagement, TechSupport, "remove");

            eachAnimation(path5, RentSuccess, TechnicalReview, "add");
            await delay(2000);
            eachAnimation(path5, RentSuccess, TechnicalReview, "remove");
          
        }
    }
    function delay(ms) {
      return new Promise((resolve) => setTimeout(resolve, ms));
  }
  animateCards();
    };
    

    const eachAnimation = (path, card1, card2, action) => {

      const text1 = card1.querySelector('span');
      const text2 = card2.querySelector('span');


      if (action === "add") {
        path.classList.add("animate-now");
        card1.classList.add("animate-now");
       // card2.classList.add("animate-now-delay");
        setTimeout(() => {
          card2.classList.add('animate-now-delay');
      }, 1100);

        if (text1) text1.classList.add("show-text");
        if (text2) text2.classList.add("show-text");
      } else if (action === "remove") {
        path.classList.remove("animate-now");
        card1.classList.remove("animate-now");
        card2.classList.remove("animate-now-delay");
      }
    };

    // Initial drawing and animation
    drawLines();
    animatingLine();

    // Event listeners
   // window.addEventListener("resize", connectElements);

    // Cleanup function
    return () => {
      window.removeEventListener("resize", connectElements);
      // Clean up any SVGs or elements created if necessary
    };
  }, []); // Empty dependency array to run only on mount

  return (
    <div className="container1">
    
      <div className="wrappers1"></div>
    </div>
  );
};

export default Rentsuccessanimation;
